import axios from "axios";

const BrokerDataService = {


createBroker: (brokerName, phoneNumber, email, userId, broker_company) => { // Update the parameter names
  return axios.post("/brokers", { brokerName, phoneNumber, email, userId, broker_company }); // Update the request body field name
},


getBrokersByUserId: (userId) => {
  return axios.get(`/brokers/${userId}`);
},


updateBroker: (id, updatedBroker, userId) => {

  // Including userId in the request body
  const data = {
    ...updatedBroker,
    userId
  };

  return axios.put(`/brokers/${id.toString()}`, data);
},
  
deleteBroker: (id, userId) => {
  return axios.delete(`/brokers/${id.toString()}`, { params: { userId } });
},

  searchBrokers: (searchTerm, userId) => {
    return axios.get(`/brokers/search/${searchTerm}`, {
      params: {
        userId: userId
      }
    }); // Update the endpoint to match the server code
  },
  
   // Function to get a broker by ID
   getBrokerById: (id, userId) => {
    return axios.get(`/brokers/profile/${id}`, { params: { userId } });
  }  
  
};

export default BrokerDataService;
