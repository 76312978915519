import React from 'react'
import '../../styles/DueDiligenceStyles.css';
import CalendarPage from './CalendarPage';
import BusinessDataService from "../../services/BusinessDataService";
import TaskDataService from '../../services/TaskDataService';


  

const DueDiligence = ({business, userId}) => {

  const duaDilingenceCompleted = async (id) => {
    try{
    await BusinessDataService.updateDuaDilingenceCompleted(id, true);
    window.location.reload();
    } catch (error){
      console.error(error);
    }
  }; 
  
  
  const notInterested = async (id) => { 
    try {
      await BusinessDataService.updateInterestedStatus(id, false);
      // Call completeTasksForBusiness after updating the interested status
      await TaskDataService.completeTasksForBusiness(business.id, userId); 
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }; 


  return (
    <div className="due-diligence-container">
      <div className="due-diligence-due-diligence-header">
        <div className="due-diligence-main-information">
          <h1 className="due-diligence-business-name Headers">{business.name}</h1>
          <span className="due-diligence-selling-price Content">Selling Price: {business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}</span>
          <span className="due-diligence-gross-revenue Content">Gross Revenue: {business.gross_revenue ? `$${business.gross_revenue.toLocaleString()}` : 'N/A'}</span>
          <span className="due-diligence-sde Content">SDE: {business.sde ? `$${business.sde.toLocaleString()}` : 'N/A'}</span>
        </div>
        <div className="due-diligence-buttons">
          <button type="button" className="due-diligence-button button Content" onClick={() => duaDilingenceCompleted(business.id)} >
            Due Diligence Complete
          </button>
          <button type="button" className="due-diligence-button1 button Content" onClick={() => notInterested(business.id)}>
            Not Interested
          </button>
        </div>
      </div>
      <div className="due-diligence-main">
        <div className="due-diligence-calendar-component">
          <CalendarPage business={business} userId={userId}/>
        </div>
      </div>
    </div>
  )
}

export default DueDiligence
