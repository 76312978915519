import axios from "axios";

const LoginDataService = {
    createUser: (userId, name, email) => {
      return axios.post("/login", { userId, name, email });
    },

    getUser: (userId) => {
      return axios.get(`/login?userId=${userId}`);
    },
    
    updateUser: (userId, updatedUser) => {
      return axios.put(`/users/${userId}`, updatedUser);
    },

    getUserData: (userId) => {
      return axios.get(`/users/data/${userId}`)
        .then(response => {
          // Handle the response data here
          // The response data will include brokers, businesses, offers, tasks, and taxYear
          const { brokers, businesses, offers, tasks, taxYear } = response.data;
          
          // You can process or return the data as needed
          return {
            brokers,
            businesses,
            offers,
            tasks,
            taxYear
          };
        })
        .catch(error => {
          // Handle any errors here
          console.error('Error fetching user data:', error);
          throw error; // Rethrow or handle as needed
        });
    }
    
    
  };
  
  export default LoginDataService;
  
