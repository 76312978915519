import React, { useState, useEffect } from 'react';
import TaskDataService from '../../services/TaskDataService';
import "../../styles/AddTask.css";
import { useParams } from "react-router-dom";
import BusinessDataService from "../../services/BusinessDataService";
import { useNavigate } from "react-router-dom";

const AddTask = ({user}) => {
  const [newTask, setNewTask] = useState('');
  const [startDate, setStartDate] = useState(''); // Define startDate state
  const [dueDate, setDueDate] = useState('');     // Define dueDate state
  const [tasks, setTasks] = useState([]);
  const { businessId } = useParams();
  const [business, setBusiness] = useState(null);
  const navigate = useNavigate();
  const userId = user.sub;


  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await BusinessDataService.getBusinessById(businessId, userId);
        const fetchedBusiness = response.data;
        setBusiness(fetchedBusiness);
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    fetchBusinessData();
  }, [businessId, userId]); // Include userId in the dependency array
  

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const taskData = {
        taskName: newTask,
        completed: false,
        business_id: business.id,
        user_id: business.user_id,
        start_date: startDate === '' ? null : startDate, 
        due_date: dueDate === '' ? null : dueDate,
      };
  
      TaskDataService.createTaskDueDiligence(taskData)
        .then(() => {
          console.log('Task created successfully');
          setTasks([...tasks, taskData]);
          setNewTask('');
          navigate(`/businessProfile/${business.id}`);
        })
        .catch((error) => {
          console.error('Error creating task:', error);
        });
    }
  };  

  return (
    <div className="add-task-container">
      <div className="add-task-header Headers">
        <h1>Add Task</h1>
      </div>
      <div className="add-task-form-container">
        <form className="add-task-form">
          <input
            type="text"
            placeholder="Task Name"
            className="add-task-task-name input"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
          />
          <div className="add-task-date-input">
            <div className="add-task-start-date">
              <span>Start Date</span>
              <input
                type="date"
                placeholder="placeholder"
                className="add-task-textinput input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="add-task-due-date">
              <div className="add-task-container1">
                <span>Due Date</span>
                <input
                  type="date"
                  placeholder="placeholder"
                  className="add-task-textinput1 input"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="add-task-button-container">
        <button
          type="button"
          className="add-task-submit-button button"
          onClick={handleAddTask}
        >
          Add Task
        </button>
      </div>
    </div>
  );  
}

export default AddTask;
