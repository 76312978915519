import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BrokerDataService from "../../services/BrokerDataService";
import "../../styles/AddBroker.css";
import "../../styles/Styles.css";
import UserLimitDataService from "../../services/UserLimitDataService";

const CreateBroker = ({ user }) => {

  // Initialize state for brokerName, phoneNumber, and email
  const [brokerName, setBrokerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [brokerCompany, setBrokerCompany] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  // Get the navigate function from react-router-dom
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
  
    // Get the user ID from the user object
    const userId = user.sub;
  
    // First, check if the user has reached their broker limit
    UserLimitDataService.checkBrokerLimit(userId)
      .then(hasLimit => {
        if (hasLimit) {
          // If the user is within the limit, proceed to create the broker
          BrokerDataService.createBroker(brokerName, phoneNumber, email, userId, brokerCompany)
            .then(response => {
              const brokerId = response.data.id; // Access the broker ID from the response
  
              // Increment the broker count for the user
              return UserLimitDataService.incrementBrokerCount(userId).then(() => brokerId);
            })
            .then(brokerId => {
              // Reset the form fields and display a success message
              setBrokerName("");
              setPhoneNumber("");
              setEmail("");
              setBrokerCompany("");
  
              // Navigate to the broker profile page using the broker ID
              navigate(`/broker/${brokerId}`);
            })
            .catch(error => {
              // If there was an error creating the broker or incrementing the count, log it and display an error message
              console.error(error);
              alert('Failed to add broker');
            });
        } else {
          // If the user has reached the limit, display an alert
          alert('You have reached your limit for adding brokers.');
        }
      })
      .catch(error => {
        // If there was an error checking the broker limit, log it and display an error message
        console.error("Error checking broker limit", error);
        alert('Error checking broker limit');
      });
  }  
  
  
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const onlyDigits = value.replace(/\D/g, '');

    setPhoneNumber(onlyDigits); // Update the phone number in the state

    if (onlyDigits.length === 10) {
        setPhoneNumberError(null);
    } else if (onlyDigits.length > 10) {
        setPhoneNumberError("Phone number is too long. Please enter a valid 10-digit phone number.");
    } else if (onlyDigits.length < 10) {
        setPhoneNumberError("Phone number is too short. Please enter a valid 10-digit phone number.");
    }
};



function formatPhoneNumber(phoneNumber) {
  // Remove any non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the phone number has 10 digits (typical in the US)
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // If it doesn't have 10 digits, return the original input
  return phoneNumber;
}


  return (
    <div className="create-broker-container">
      <div className="create-broker-heading-container">
        <h1 className="create-broker-title Headers">Create Broker</h1>
      </div>
      <form className="create-broker-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Broker Company:"
          className="create-broker-broker-company input Content"
          value={brokerCompany}
          onChange={(e) => setBrokerCompany(e.target.value)}
        />
        <input
          type="text"
          placeholder="Broker Name:"
          className="create-broker-broker-name input Content"
          value={brokerName}
          onChange={(e) => setBrokerName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Phone Number: (xxx)-xxx-xxxx"
          className="create-broker-phone-number input Content"
          value={formatPhoneNumber(phoneNumber)}
          onChange={handlePhoneNumberChange}
        />
        {phoneNumberError && <p style={{ color: 'red' }}>{phoneNumberError}</p>}
        <input
          type="text"
          placeholder="Email:"
          className="create-broker-email input Content"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          type="submit"
          className="create-broker-create-button button Content"
          disabled={phoneNumberError}
        >
          Create Broker
        </button>
      </form>
    </div>
  )
}

export default CreateBroker
