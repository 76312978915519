import React, { useState } from 'react';
import '../../styles/CreateTaxYear.css';
import { useParams, useNavigate } from "react-router-dom";
import TaxYearDataService from '../../services/TaxYearDataService';

const CreateTaxYear = ({ user }) => {

    const user_id = user.sub; // updated variable name
    const navigate = useNavigate();

    // Initialize state for the form fields
    const [year, setYear] = useState('');
    const [sales, setSales] = useState('');
    const [cogs, setCogs] = useState(''); // updated variable name
    const [gross_profit, setGrossProfit] = useState(''); // updated variable name
    const [expenses, setExpenses] = useState(''); // updated variable name
    const [net_profit, setNetProfit] = useState(''); // updated variable name
    // Assuming business_id will be a constant or coming from some other data source
    const { businessId: business_id } = useParams();

    const sanitizeAndConvertToDecimal = (str) => {
        const sanitizedStr = str.replace(/[^0-9.]/g, ''); // remove non-digits except for dot
        const value = parseFloat(sanitizedStr) || 0;
        return Math.round(value * 100) / 100; // round to two decimal places
    }
    
    

    const handleSave = async () => {
        const taxYearToCreate = {
            user_id,
            year,
            sales: sanitizeAndConvertToDecimal(sales),
            cogs: sanitizeAndConvertToDecimal(cogs),
            gross_profit: sanitizeAndConvertToDecimal(gross_profit),
            expenses: sanitizeAndConvertToDecimal(expenses),
            net_profit: sanitizeAndConvertToDecimal(net_profit),
            business_id
        };
    
        try {
            const response = await TaxYearDataService.createTaxYear(taxYearToCreate);
            // Handle the successful response (e.g., display a success message)
            console.log('Data saved successfully:', response);
            navigate(`/businessProfile/${business_id}`);
        } catch (error) {
            // Handle the error response (e.g., display an error message)
            console.error('Error saving data:', error);
            // If you have some sort of user interface alert or modal, you can show it here
            // For example: alert('There was a problem saving the data. Please try again.');
        }
    };    

    const handleSaveAndClear = async () => {
        const taxYearToCreate = {
            user_id,
            year,
            sales: sanitizeAndConvertToDecimal(sales),
            cogs: sanitizeAndConvertToDecimal(cogs),
            gross_profit: sanitizeAndConvertToDecimal(gross_profit),
            expenses: sanitizeAndConvertToDecimal(expenses),
            net_profit: sanitizeAndConvertToDecimal(net_profit),
            business_id
        };
    
        try {
            const response = await TaxYearDataService.createTaxYear(taxYearToCreate);
            // Handle the successful response (e.g., display a success message)
            console.log('Data saved successfully:', response);
            
            // Clear the input values
            setYear('');
            setSales('');
            setCogs('');
            setGrossProfit('');
            setExpenses('');
            setNetProfit('');
    
        } catch (error) {
            // Handle the error response (e.g., display an error message)
            console.error('Error saving data:', error);
            // If you have some sort of user interface alert or modal, you can show it here
            // For example: alert('There was a problem saving the data. Please try again.');
        }
    };
    

    return (
        <div className="create-tax-year-container">
             <div className="create-tax-year-header Sub-Headers">
                <h1>Add Tax Year</h1>
            </div>
            <div className="create-tax-year-form-container">
            <form className="create-tax-year-form">
    <input
        type="text"
        placeholder="Year:"
        className="create-tax-year-year input"
        value={year}
        onChange={e => setYear(e.target.value)}
    />
    <input
        type="text"
        placeholder="Gross Receipts/Sales:"
        className="create-tax-year-sales input"
        value={sales}
        onChange={e => setSales(e.target.value)}
    />
    <input
        type="text"
        placeholder="Cost of Goods:"
        className="create-tax-year-cost-of-goods input"
        value={cogs}
        onChange={e => setCogs(e.target.value)}
    />
    <input
        type="text"
        placeholder="Gross Profit:"
        className="create-tax-year-gross-profit input"
        value={gross_profit}
        onChange={e => setGrossProfit(e.target.value)}
    />
    <input
        type="text"
        placeholder="Total Expenses:"
        className="create-tax-year-total-expenses input"
        value={expenses}
        onChange={e => setExpenses(e.target.value)}
    />
    <input
        type="text"
        placeholder="Net Profit/Loss: "
        className="create-tax-year-net-profit-loss input"
        value={net_profit}
        onChange={e => setNetProfit(e.target.value)}
    />
</form>

            </div>
            <div className="create-tax-year-button-container">
                <button type="button" className="create-tax-year-one-year button" onClick={handleSave}>
                    Add One Tax Year
                </button>
                <button type="button" className="create-tax-year-next-year button" onClick={handleSaveAndClear}>
                    Next Year
                </button>
                
            </div>
        </div>
    )
}

export default CreateTaxYear;
