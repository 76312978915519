import React, { useState, useEffect } from 'react';
import TaxYearDataService from '../../services/TaxYearDataService';
import {useNavigate } from "react-router-dom";
import '../../styles/SBALoanCalcStyles.css';
import OfferDataService from "../../services/OfferDataService";

const NewSBALoanCalc = ({ business, userId }) => {
    const [netProfit, setNetProfit] = useState();
    const [PurchasePrice, setPurchasePrice] = useState(business.selling_price);
    const [Downpayment, setDownPayment] = useState(business.selling_price * 0.10);
    const [DownpaymentPercentage, setDownPaymentPercentage] = useState(10);
    const [SBAInterestRate, setSBAInterestRate] = useState(null);
    const [rawSBAInterestRate, setRawSBAInterestRate] = useState('');
    const [LoanTerm, setLoanTerm] = useState(10);
    const [SellerInterestRate, setSellerInterestRate] = useState("4");
    const [SellerFinancing, setSellerFinancing] = useState(business.selling_price * 0.10);  // Default: 10% of selling price
    const [SellerFinancingPercentage, setSellerFinancingPercentage] = useState(10);  // Default: 10%
    const [SellerLoanTerm, setSellerLoanTerm] = useState(5);  // Default: 5 years
    const [bankFee, setBankFee] = useState(0);
    const [rawBankFee, setRawBankFee] = useState(0);
    const [dueDiligence, setDueDiligence] = useState(0);
    const [rawDueDiligence, setRawDueDiligence] = useState(0);
    const [workingCapital, setWorkingCapital] = useState(0);
    const [rawWorkingCapital, setRawWorkingCapital] = useState(0);
    const [additionalFees, setAdditionalFees] = useState(0);
    const [rawAdditionalFees, setRawAdditionalFees] = useState(0);
    const [showMoreFees, setShowMoreFees] = useState(false);  // To toggle the drop-down visibility
    let loanTotal = 0;
    let sbaFee = 0;
    let SBAMonthly = 0;
    let SellerMonthly = 0;
    const navigate = useNavigate();

    useEffect(() => {
      const fetchSBAInterestRate = async () => {
        try {
          const rate = await OfferDataService.getSBAInterestRate(); // Fetch the SBA interest rate
          if (rate) {
            const SbaIR = parseFloat(rate) + 2.75;
            setSBAInterestRate(SbaIR/100); // Set the SBAInterestRate as a decimal
            setRawSBAInterestRate(SbaIR); // Set the rawSBAInterestRate as a string with 2 decimal places
          } else {
            // Handle the case where no rate is returned
            console.log('No SBA Interest Rate found.');
            // You might set a default value or handle this scenario as needed
          }
        } catch (error) {
          console.error('Failed to fetch SBA Interest Rate:', error);
          // Handle error, possibly setting default values or providing user feedback
        }
      };
    
      fetchSBAInterestRate();
    }, []); // The empty dependency array ensures this effect only runs once on component mount
    

    function sbaGuaranteeFee(loanAmount) {
      let guaranteeFee = 0;
    
      if (loanAmount <= 500000) {
        guaranteeFee = 0;
      } else if (loanAmount <= 700000) {
        guaranteeFee = loanAmount * 0.75 * 0.0055; // Corrected rate to 0.55%
      } else if (loanAmount <= 1000000) {
        guaranteeFee = loanAmount * 0.75 * 0.0375; // Corrected rate to 3.75%
      } else {
        guaranteeFee = ((loanAmount * 0.75) - 1000000) * 0.0375 + (1000000 * 0.035); // Calculating the fee for amounts over $1,000,000
      }
    
      return guaranteeFee;
    }

    const calcMonthlySBAPayment = () => {
      const principalWithoutFees = parseFloat(PurchasePrice) - parseFloat(Downpayment) - parseFloat(SellerFinancing);  // subtract downpayment from purchase price
      const totalFees = parseFloat(bankFee) + parseFloat(dueDiligence) + parseFloat(workingCapital) + parseFloat(additionalFees); // sum up all the fees
      const principal = principalWithoutFees + totalFees; // add the total fees to the principal without fees
      sbaFee = sbaGuaranteeFee(principal);
      const principalwithfees = sbaFee + principal;
      loanTotal = principalwithfees;
      
      const interestRate = SBAInterestRate * 100;
      const loanTerm = LoanTerm * 12;
      const monthlyInterestRate = interestRate / 12 / 100;
      const z = Math.pow(1 + monthlyInterestRate, -loanTerm);
      const monthlyPayment = principalwithfees * monthlyInterestRate / (1 - z);
      SBAMonthly = monthlyPayment;
  
      return monthlyPayment;
  }
  

    const calcMonthlySellerPayment = (principal) => {
      const interestRate = parseFloat(SellerInterestRate) || 0;
      const loanTerm = SellerLoanTerm*12;
      const monthlyInterestRate = interestRate / 12 / 100;
      const z = Math.pow(1 + monthlyInterestRate, -loanTerm);
      const monthlyPayment = principal * monthlyInterestRate / (1 - z);
      SellerMonthly = monthlyPayment;
    
      return monthlyPayment;
  }

  const CashonCashConcurrent = () => {
    const annualCashflow = (cashflowwithseller()*12);
    const CoC = ((annualCashflow/parseFloat(loanTotal))*100);
    return CoC;
  }

  const CashonCashDelay = () => {
    const annualCashflow = (cashflowwithoutseller()*12)
    const CoC = ((annualCashflow/parseFloat(loanTotal))*100);
    return CoC;
  }

  const handleInputChange = (e, setStateFunc) => {
    const val = e.target.value.replace(/\D/g,''); // remove all non-digits
    const number = val ? parseInt(val, 10) : 0; // parse it as an integer
    setStateFunc(number); // set the state with the numerical value
};

const handleDownpaymentChange = (inputValue) => {
    const val = inputValue.replace(/\D/g,''); // remove all non-digits
    const number = val ? parseInt(val, 10) : 0; // parse it as an integer
    setDownPayment(number); // set the state with the numerical value

    if (number && PurchasePrice) {
        const percentage = (number / PurchasePrice) * 100;
        setDownPaymentPercentage(percentage.toFixed(2));  // 2 decimal places for percentage
    } else {
        setDownPaymentPercentage(0);
    }
};
    
    const handleDownpaymentPercentageChange = (inputValue) => {
        // Keep the raw value as is
        setDownPaymentPercentage(inputValue);
    
        const parsedPercentage = parseFloat(inputValue);
        if (!isNaN(parsedPercentage)) {
            const amount = (parsedPercentage / 100) * PurchasePrice;
            setDownPayment(amount.toFixed(2));  // 2 decimal places for amount
        } else {
            setDownPayment("");
        }
    };

    const handleSellerFinancingChange = (inputValue) => {
      // Remove non-numeric characters for processing
      const numericValue = inputValue.replace(/[^\d.-]/g, '');
      const parsedValue = parseFloat(numericValue);
  
      if (!isNaN(parsedValue) && parsedValue >= 0) {
          // Set the numerical value for calculations
          setSellerFinancing(parsedValue);
          // Calculate and set the percentage based on PurchasePrice
          const percentage = (PurchasePrice > 0) ? (parsedValue / PurchasePrice) * 100 : 0;
          setSellerFinancingPercentage(percentage.toFixed(2));
      } else {
          setSellerFinancing(0);
          setSellerFinancingPercentage("");
      }
  };  

  const handleSellerFinancingPercentageChange = (inputValue) => {
      // Update percentage and calculate the amount based on PurchasePrice
      setSellerFinancingPercentage(inputValue);
      const parsedPercentage = parseFloat(inputValue);
      if (!isNaN(parsedPercentage)) {
          const amount = (parsedPercentage / 100) * PurchasePrice;
          setSellerFinancing(amount.toFixed(2));
      } else {
          setSellerFinancing("");
      }
  };

  const handleSBAInterestRateChange = (e) => {
    // Update the raw input value on each keystroke
    setRawSBAInterestRate(e.target.value);
};

const handleSBAInterestRateBlur = () => {
    // Update the actual SBAInterestRate state on blur
    const parsedValue = parseFloat(rawSBAInterestRate) / 100;
    if (!isNaN(parsedValue)) {
        setSBAInterestRate(parsedValue);
    }
};

const handleBankFeeChange = (e) => {
  // Directly update the raw input value on each keystroke
  setRawBankFee(e.target.value.replace(/[^0-9.]/g, ''));
};

const handleBankFeeBlur = () => {
  // Format the raw input value on blur
  const numericValue = parseFloat(rawBankFee);
  if (!isNaN(numericValue)) {
      setBankFee(numericValue.toFixed(2)); // This will ensure two decimal places
  } else {
      setBankFee('0.00');
  }
};

const handleDueDiligenceChange = (e) => {
  // Directly update the raw input value on each keystroke
  setRawDueDiligence(e.target.value.replace(/[^0-9.]/g, ''));
};

const handleDueDiligenceBlur = () => {
  // Format the raw input value on blur
  const numericValue = parseFloat(rawDueDiligence);
  if (!isNaN(numericValue)) {
      setDueDiligence(numericValue.toFixed(2)); // This will ensure two decimal places
  } else {
    setDueDiligence('0.00');
  }
};

const handleWorkingCapitalChange = (e) => {
  // Directly update the raw input value on each keystroke
  setRawWorkingCapital(e.target.value.replace(/[^0-9.]/g, ''));
};

const handleWorkingCapitalBlur = () => {
  // Format the raw input value on blur
  const numericValue = parseFloat(rawWorkingCapital);
  if (!isNaN(numericValue)) {
      setWorkingCapital(numericValue.toFixed(2)); // This will ensure two decimal places
  } else {
      setWorkingCapital('0.00');
  }
};

const handleAdditionalFeesChange = (e) => {
  // Directly update the raw input value on each keystroke
  setRawAdditionalFees(e.target.value.replace(/[^0-9.]/g, ''));
};

const handleAdditionalFeesBlur = () => {
  // Format the raw input value on blur
  const numericValue = parseFloat(rawAdditionalFees);
  if (!isNaN(numericValue)) {
      setAdditionalFees(numericValue.toFixed(2)); // This will ensure two decimal places
  } else {
    setAdditionalFees('0.00');
  }
};

  
  const cashflowwithseller = () => {
    const sbaDebt = (calcMonthlySBAPayment(PurchasePrice));
    const sellerDebt = (calcMonthlySellerPayment(SellerFinancing));
    const cashflow = (netProfit/12) - sbaDebt - sellerDebt;
    return cashflow;

  }

  const cashflowwithoutseller = () => {
    const sbaDebt = (calcMonthlySBAPayment(PurchasePrice));
    const cashflow = (netProfit/12) - sbaDebt;
    return cashflow;
  }

  const dscrwithseller = (principal) => {
    const sellerDebtYearly = (calcMonthlySellerPayment(principal)*12);
    const sbaDebtyearly = (calcMonthlySBAPayment()*12)
    const totaldebt = sbaDebtyearly + sellerDebtYearly;
    const dscr = netProfit/totaldebt;
    return dscr;
  }

  const dscrwithoutseller = () => {
    const sbaDebtyearly = (calcMonthlySBAPayment()*12)
    const dscr = netProfit/sbaDebtyearly;
    return dscr;
  }

  const constructOffer = () => {
    if (PurchasePrice == null || Downpayment == null || SellerFinancing == null) {
      alert("Missing values!");
      return;
    }
  
    const newOffer = {
      purchasePrice: parseInt(PurchasePrice),
      downPayment: parseInt(Downpayment),
      sellerFinancing: parseInt(SellerFinancing),
      sbaInterestRate: SBAInterestRate,
      sellerInterestRate: SellerInterestRate,
      cashflowWithSeller: cashflowwithseller(),
      cashflowDelay: cashflowwithoutseller(),
      SBALoanTotal: parseInt(loanTotal),
      sellerfinancing_percent: parseFloat(SellerFinancingPercentage), 
      downpayment_percent: parseFloat(DownpaymentPercentage), 
      businessId: business.id,
      concurrentDSCR: dscrwithseller(SellerFinancing),
      delayDSCR: dscrwithoutseller(),
      bankFee: parseFloat(bankFee),  // adding the bank fee to the offer
      dueDiligence: parseFloat(dueDiligence), // adding the due diligence fee to the offer
      workingCapital: parseFloat(workingCapital), // adding the working capital fee to the offer
      additionalFees: parseFloat(additionalFees), // adding the additional fees to the offer
      sba_guarantee_fee: parseInt(sbaFee),
      sbaLoanTerm: LoanTerm,
      sellerLoanTerm: SellerLoanTerm,
      sbamonthlypayment: SBAMonthly,
      sellermonthlypayment: SellerMonthly,
      netProfit: netProfit
    };   
    
    return newOffer;
};


  const handleSaveOfferAsync = async (offerData) => {
    console.log("Offer before Service is sent: ", offerData.purchasePrice);
    
    try {
      const response = await OfferDataService.createOffer(offerData, userId);
      const offerId = response.data.offerId;
      navigate(`/offer/${offerId}`);
    } catch (error) {
      console.error(error);
      alert('Failed to add offer');
    }
  };
  

  const handleSaveOffer = () => {
    const newOffer = constructOffer();
    if (newOffer) {
      handleSaveOfferAsync(newOffer);
    }
  };
  
  useEffect(() => {
    // Set Downpayment to 10% of PurchasePrice
    setDownPayment(PurchasePrice * 0.10);
    setSellerFinancing(PurchasePrice * 0.10)

    // If you also want to keep the DownpaymentPercentage in sync, you can update it here too.
    setDownPaymentPercentage(10);
    setSellerFinancingPercentage(10);
}, [PurchasePrice]);


    useEffect(() => {
        const businessId = business?.id;
        if (businessId) {
          TaxYearDataService.getTaxYearById(businessId, userId)
            .then((data) => {
              setNetProfit(data[0].average_net_profit);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }, [business?.id, userId]);



    return (
        <div className="sba-loan-calc-container">
          <div className="sba-loan-calc-header-container Content">
            <h1 className='Sub-Headers'>SBA Loan Calculator</h1>
          </div>
          <div className="sba-loan-calc-container1 Content">
            <div className="sba-loan-calc-calc-input-container">
            <span>Purchase Price:</span>
            <div className="input-container">
              <div className="input-group">
                <input
                        type="text"
                        placeholder="Purchase Price"
                        className="sba-loan-calc-purchase-price-input currency-input Content"
                        value={(PurchasePrice? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(PurchasePrice) : '$0')}
                        onChange={(e) => handleInputChange(e, setPurchasePrice, parseFloat)}
                    />
              </div>
            </div>
            <span>Down payment:</span>
                <div className="sba-loan-calc-down-payment-container">
                <input
                      type="text"
                      placeholder="Down payment"
                      className="sba-loan-calc-downpayment-number currency-input Content"
                      value={Downpayment ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(Downpayment) : '$0'}
                      onChange={(e) => handleDownpaymentChange(e.target.value)}
                  />

                    <input
                        type="text"
                        placeholder="%"
                        className="sba-loan-calc-downpayment-percentage percent-input Content"
                        value={DownpaymentPercentage}
                        onChange={(e) => handleDownpaymentPercentageChange(e.target.value)}
                    />
                    <label>%</label>
                </div>
              <span>Loan Term:</span>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Loan Term"  // Corrected typo from "Loan Team" to "Loan Term"
                        className="sba-loan-calc-purchase-price-input currency-input Content"
                        value={LoanTerm}
                        onChange={(e) => {
                            const inputVal = e.target.value;
                            if (inputVal === "" || (parseInt(inputVal, 10) > 0 && !isNaN(inputVal))) {
                                setLoanTerm(inputVal);
                            }
                        }}
                    />
                    <label>Years</label>
                </div>
              <span>SBA Interest Rate</span>
              <input
                  type="text"
                  placeholder="SBA Interest Rate"
                  className="sba-loan-calc-sba-ir-input currency-input Content"
                  value={rawSBAInterestRate}
                  onChange={handleSBAInterestRateChange}
                  onBlur={handleSBAInterestRateBlur}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSBAInterestRateBlur(); }}
              />
            <div className="more-fees-dropdown">
            <button className='calc-buttons Content' onClick={() => setShowMoreFees(!showMoreFees)}>More fees</button>

            {showMoreFees && (
                <div className="more-fees-dropdown">
                    <label>Bank fee:</label>
                    <input
                        type="text"
                        className='currency-input Content'
                        placeholder="Bank fee"
                        value={rawBankFee? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(rawBankFee) : '$0'}
                        onChange={handleBankFeeChange}
                        onBlur={handleBankFeeBlur}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleBankFeeBlur(); }}
                    />
                    <label>Due Diligence:</label>
                    <input
                        type="text"
                        placeholder="Due Diligence"
                        className='currency-input Content'
                        value={rawDueDiligence? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(rawDueDiligence) : '$0'}
                        onChange={handleDueDiligenceChange}
                        onBlur={handleDueDiligenceBlur}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleDueDiligenceBlur(); }}
                    />

                    <label>Working capital:</label>
                    <input
                        type="text"
                        placeholder="Working capital"
                        className='currency-input Content'
                        value={rawWorkingCapital? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(rawWorkingCapital) : '$0'}
                        onChange={handleWorkingCapitalChange}
                        onBlur={handleWorkingCapitalBlur}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleWorkingCapitalBlur(); }}
                    />
                    <label>Additional Fees:</label>
                    <input
                        type="text"
                        placeholder="Additonal Fees"
                        className='currency-input Content'
                        value={rawAdditionalFees? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(rawAdditionalFees) : '$0'}
                        onChange={handleAdditionalFeesChange}
                        onBlur={handleAdditionalFeesBlur}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleAdditionalFeesBlur(); }}
                    />
                </div>
            )}
        </div>
            <h1 className='Sub-Headers'>Seller Financing</h1>
            <span>Sellers Financing: </span>
          <div className="sba-loan-calc-sellers-financing-container">
              <input
                  type="text"
                  placeholder="Seller's Financing"
                  className="sba-loan-calc-sellers-financing-number currency-input Content"
                  value={SellerFinancing? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(SellerFinancing) : '$0'}
                  onChange={(e) => handleSellerFinancingChange(e.target.value)}
              />
              <input
                  type="text"
                  placeholder="%"
                  className="sba-loan-calc-sellers-financing-percentage percent-input Content"
                  value={SellerFinancingPercentage}
                  onChange={(e) => handleSellerFinancingPercentageChange(e.target.value)}
              />
             <label >%</label>
          </div>
          <span>Loan Term:</span>
                <div className="input-group">
                <input
                    type="text"
                    placeholder="Loan Term"
                    className="sba-loan-calc-purchase-price-input currency-input Content"
                    value={SellerLoanTerm}
                    onChange={(e) => {
                        const inputVal = e.target.value;
                        if (inputVal === "" || (parseInt(inputVal, 10) > 0 && !isNaN(inputVal))) {
                            setSellerLoanTerm(inputVal);
                        }
                    }}
                />
                <label>Years</label>
              </div>
          <span>Seller's Interest Rate</span>
          <input
              type="text"
              placeholder="Seller's Interest Rate"
              className="sba-loan-calc-sellers-ir-input currency-input Content"
              value={SellerInterestRate}
              onChange={(e) => setSellerInterestRate(e.target.value)}
          />
          <button type="button" className="sba-loan-calc-button calc-buttons Content" onClick={handleSaveOffer}>
            Save Offer
          </button>
          </div>
          <div className="sba-loan-calc-chart-container">
            <h4>SBA Monthly Payment:</h4>
            <h1>{calcMonthlySBAPayment(PurchasePrice).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })}</h1>
            <h4>Seller Monthly Payment:</h4>
            <h1>{calcMonthlySellerPayment(SellerFinancing).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}</h1>
            <div className="sba-loan-calc-results-container Content">
              <table>
                <tbody>
                  <tr>
                    <th>Financing Terms</th>
                    <th>Monthly Cashflow</th>
                    <th>Annual Cashflow</th>
                    <th>DSCR</th>
                    <th>CoC</th>
                  </tr>
                  <tr>
                  <td className="financing-terms">Concurrent</td>
                    <td>{cashflowwithseller().toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    <td>{(cashflowwithseller()*12).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    <td>{dscrwithseller(SellerFinancing).toFixed(2)}</td>
                    <td>{CashonCashConcurrent().toFixed(2)}%</td>
                  </tr>
                  <tr>
                  <td className="financing-terms">Delay</td>
                    <td>{cashflowwithoutseller().toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    <td>{(cashflowwithoutseller()*12).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    <td>{dscrwithoutseller().toFixed(2)}</td>
                    <td>{CashonCashDelay().toFixed(2)}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="sba-loan-calc-offers-table">
            <div style={{ flex: 1, marginLeft: "20px" }}>
            <h3 className='Sub-Headers'>Offers</h3>
              <div className="business-table"><table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Purchase Price</th>
                    <th>CashFlow Concurrent</th>
                    <th>DSCR</th>
                    <th>CashFlow Delay</th>
                    <th>DSCR</th>
                  </tr>
                </thead>
                <tbody>
                  {business && business.offers && business.offers.length > 0 ? (
                    business.offers.map((offer, index) => (
                    <tr key={offer.id}>
                      <td>{index + 1}</td>
                      <td>
                        <a href={`/offer/${offer.id}`}>{offer.purchase_price ? `$${offer.purchase_price.toLocaleString()}` : 'N/A'}</a>
                      </td>
                      <td>{offer.cashflow_concurrent? `$${Number(offer.cashflow_concurrent).toLocaleString()}` : 'N/A'}</td>
                      <td>{offer.concurrent_dscr}</td>
                      <td>{offer.cashflow_delay? `$${Number(offer.cashflow_delay).toLocaleString()}` : 'N/A'}</td>
                      <td>{offer.delay_dscr}</td>
                    </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No offer added yet.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
);
}
export default NewSBALoanCalc;