import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BusinessDataService from "../../services/BusinessDataService";
import "../../styles/BusinessProfileStyles.css";
import SubmitNda from "./SubmitNDA";
import ProgressBar from "../ProgressBar";
import PreLOI from "./PreLOI";
import SubmitLOI from "./SubmitLOI";
import CalendarPage from "./CalendarPage";
import DueDiligence from "./DueDiligence";

const BusinessProfile = ({user}) => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [business, setBusiness] = useState(null);
  const userId = user.sub;


  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await BusinessDataService.getBusinessById(id, userId);
        const fetchedBusiness = response.data;
        setBusiness(fetchedBusiness);
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    fetchBusinessData();
  }, [id]);

  if (!business) {
    return <div>Loading...</div>;
  }

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="progress-bar-container">
      <ProgressBar
        activeStep={activeStep}
        onStepClick={handleStepClick}
        business={business}
      />
      <div className="content-container">
        {activeStep === 0 && (
          <SubmitNda
            user={user}
            business={business}
            id={id}
            setBusiness={setBusiness}
          />
        )}
        {activeStep === 1 && (
        <PreLOI business={business} userId={userId}/>
        )}
        {activeStep === 2 && (
          <SubmitLOI business={business} userId={userId} />
        )}
        <div className="calendar-page-container">
        {activeStep === 3 && (
          <DueDiligence business={business} userId={userId}/>
        )}
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;