import axios from "axios";

const OfferDataService = {

  createOffer: (offer, userId) => {
    return axios.post("/offer", {
      purchase_price: offer.purchasePrice,
      downpayment: offer.downPayment,
      seller_financing: offer.sellerFinancing,
      sba_ir: offer.sbaInterestRate,
      seller_ir: offer.sellerInterestRate,
      cashflow_concurrent: offer.cashflowWithSeller,
      cashflow_delay: offer.cashflowDelay,
      sba_loan_total: offer.SBALoanTotal,
      sellerfinancing_percent: offer.sellerfinancing_percent,
      downpayment_percent: offer.downpayment_percent,
      business_id: offer.businessId,
      concurrent_dscr: offer.concurrentDSCR,
      delay_dscr: offer.delayDSCR,
      bank_fee: offer.bankFee,
      due_diligence: offer.dueDiligence,
      working_capital: offer.workingCapital,
      additional_fees: offer.additionalFees,
      sba_guarantee_fee: offer.sba_guarantee_fee,
      sba_loan_term: offer.sbaLoanTerm,
      seller_loan_term: offer.sellerLoanTerm,
      sba_monthly_payment: offer.sbamonthlypayment,
      seller_monthly_payment: offer.sellermonthlypayment,
      user_id: userId,
      net_profit: offer.netProfit,
    });
  },  
      
  getOfferById: (id, userId) => {
    return axios.get(`/offer/profile/${id}`, {
      params: { userId } // Pass the userId as a parameter
    });
  },   
  deleteOffer: (id, userId) => {
    return axios.delete(`/offers/${id.toString()}`, {
      params: { userId } // Pass the userId as a parameter
    });
  },  

  updateOffer: (id, updatedOffer) => {  
    return axios.put(`/offers/${id.toString()}`, updatedOffer); 
  }, 

  getSBAInterestRate: async () => {
    try {
      const response = await axios.get('/api/interest-rates/sba');
      return response.data.sbaInterestRate; // Assuming the backend sends the interest rate in this structure
    } catch (error) {
      console.error('Failed to fetch SBA Interest Rate:', error);
      // Handle the error appropriately - perhaps returning null or a default value
      return null;
    }
  },
      
  
};

export default OfferDataService;
