import axios from "axios";

const AnalysisDataService = {
// createSWOT function
createSWOT: (business_id, status, strengths, weaknesses, opportunities, threats) => {
    return axios.post("/swot", {
      business_id,
      status,
      strengths,
      weaknesses,
      opportunities,
      threats
    });
  },

  getAnalysisById: (id) => {
    return axios.get(`/swot/profile/${id}`, {
    });
  }, 

  deleteSwot: (id) => {
    return axios.delete(`/swot/${id.toString()}`, {
    });
  }, 

  updateSwot: (id, data) => {
    return axios.put(`/swot/${id}`, data);
  },

};

export default AnalysisDataService;
