import React, { useState, useEffect } from "react";
import BusinessDataService from "../../services/BusinessDataService";
import UserLimitDataService from "../../services/UserLimitDataService";
import "../../styles/BusinessList.css";
import {useNavigate  } from 'react-router-dom';

const BusinessesList = ({ user }) => {

  const [businesses, setBusinesses] = useState([]);
  const [editableBusiness, setEditableBusiness] = useState(null);
  const [updatedBusiness, setUpdatedBusiness] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [business, setBusiness] = useState(null);

  const navigate = useNavigate();
  const userId = user.sub

  async function getBusinesses(userId){
    const res = await fetch(`/businesses/${userId}`);
    const busArray = await res.json();
    setBusinesses(busArray);
  }
  

  useEffect(() => {
    getBusinesses(userId);
  }, [userId]);

  const handleEdit = (business) => {
    if (business) {
      setEditableBusiness(business);
      setUpdatedBusiness({ ...business });
    }
  };

  useEffect(() => {
    if (business) {
      setBusinesses((prevBusinesses) => {
        const updatedBusinesses = prevBusinesses.map((b) =>
          b.id === business.id ? { ...b, ...updatedBusiness } : b
        );
        return updatedBusinesses;
      });
    }
  }, [business, updatedBusiness]);
  

  const handleCancel = () => {
    setEditableBusiness(null);
  };

  const handleSave = (id) => {
    const processedBusiness = { ...updatedBusiness };
    
    if (typeof processedBusiness.selling_price === 'string') {
      processedBusiness.selling_price = processedBusiness.selling_price.replace('$', '').replace(/,/g, '');
    }
    
    BusinessDataService.updateBusiness(id, processedBusiness, userId)
      .then((response) => {
        if (response.status === 200) {
          // Update the 'business' state immediately
          setBusiness(processedBusiness);
        } else {
          alert("Failed to update business");
        }
        setEditableBusiness(null);
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to update business");
      });
  };    

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedBusiness({ ...updatedBusiness, [name]: value });
  };

  const handleDelete = async (id) => {
    try {
      // Decrement the business count first
      await UserLimitDataService.decrementBusinessCount(userId);
  
      // After decrementing the business count, you can delete the business
      await BusinessDataService.deleteBusiness(id, userId);
      setBusinesses(prevBusinesses =>
        prevBusinesses.filter(business => business.id !== id)
      );
    } catch (error) {
      console.error(error);
      alert("An error occurred while deleting the business."); // You can display a user-friendly error message here.
    }
  };
  


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    if(searchTerm){
    BusinessDataService.searchBusinesses(searchTerm, userId)
      .then((response) => {
        setBusinesses(response.data);
      })
      .catch((error) => {
        console.error("Error searching brokers:", error);
      });
    }else{
      getBusinesses(userId);
    }
  };

  return (
    <div className="businesses-list-container">
      <div className="businesses-list-broker-header">
        <h1 className="businesses-list-header Headers">
          <span>Businesses</span>
          <br></br>
        </h1>
        <div className="businesses-list-container1">
          <svg
            onClick={() => {navigate('/select-broker');}}
            viewBox="0 0 804.5714285714286 1024"
            className="businesses-list-icon"
          >
            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
          </svg>
          <input
            type="text"
            placeholder="Search for Business..."
            className="businesses-list-search-input input Content"
            onChange={handleSearch}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSearchClick();
                }
            }}
          />
          <svg
            onClick={handleSearchClick}
            viewBox="0 0 950.8571428571428 1024"
            className="businesses-list-icon2"
          >
            <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
          </svg>
        </div>
      </div>
      <div className="businesses-list-broker-table">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Business Name</th>
              <th>Selling Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(businesses) && businesses.map((business, index) => (
              <tr key={business.id}>
                <td>{index + 1}</td>
                <td>
                  {editableBusiness && editableBusiness.id === business.id ? (
                    <input
                      type="text"
                      name="name"
                      value={updatedBusiness.name}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <a href={`/businessProfile/${business.id}`}>{business.name}</a>
                  )}
                </td>
                <td>
                  {editableBusiness && editableBusiness.id === business.id ? (
                    <input
                      type="text"
                      name="selling_price"
                      value={updatedBusiness.selling_price.toLocaleString()}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <div>{business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}</div>
                  )}
                </td>
                <td>
                  {editableBusiness && editableBusiness.id === business.id ? (
                    <>
                      <button onClick={() => handleSave(business.id)}>
                        Save
                      </button>
                      <button onClick={handleCancel}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEdit(business)}>
                        Edit
                      </button>
                      <button onClick={() => handleDelete(business.id)}>
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}

export default BusinessesList
