import React, { useState, useEffect } from 'react';
import LoginDataService from '../../services/LoginDataService';
import RangeFilterComponent from './RangeFilterComponent';
import ListComponent from './ListComponent';
import MultiSelectPicklist from './MultiSelectPickList';
import TaskListComponent from './TaskListComponent';
import "../../styles/Dashboardstyles.css";
import KanbanBoard from './KanbanBoard';

const Dashboard = ({ user }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [targetStatement, setTargetStatement] = useState(false);
  const [priceFilter, setPriceFilter] = useState({ startPrice: 0, endPrice: 0 });
  const [sdeFilter, setSDEFilter] = useState({ startSDE: 0, endSDE: 0 });
  const [selectedPicklistOptions, setSelectedPicklistOptions] = useState([]);
  const [currentStepFilter, setCurrentStepFilter] = useState([]);
  const [currentTypeFilter, setCurrentTypeFilter] = useState([]);
  const [taskStatusFilter, setTaskStatusFilter] = useState([]);
  const [currentView, setCurrentView] = useState('Kanban');

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const userId = user.sub;
        const response = await LoginDataService.getUser(userId);
        const userData = response.data; // Store response data to a variable for easy access

        setUserInfo(userData); // Update userInfo state

        if (
          !userData.business_type ||
          !userData.business_positive ||
          !userData.business_negative ||
          !userData.sde_min ||
          !userData.sde_max
        ) {
          setTargetStatement(true);
        } else {
          // Set default filter values based on user data
          const userSDEMin = Number(userData.sde_min) || 0;
          const userSDEMax = Number(userData.sde_max) || 0; // Adjust the default max value as needed
          setSDEFilter({ startSDE: userSDEMin, endSDE: userSDEMax });
          setSelectedPicklistOptions([userData.business_type]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user.sub]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return <h1>Error: {error}</h1>;
  }

  const clearListComponentFilters = () => {
    const SDEMin = 0;
    const SDEMax = 0;
    setSDEFilter({ startSDE: SDEMin, endSDE: SDEMax });
    setPriceFilter({ startPrice: 0, endPrice: 0 });
    setSelectedPicklistOptions([]);
    setCurrentStepFilter([]);
    // Add more resets here if you have other filters for the ListComponent.
  };

  const clearTaskListComponentFilters = () => {
    setCurrentTypeFilter([]);
    setTaskStatusFilter([]);
    // Add more resets here if you have other filters for the TaskListComponent.
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-dashboard-header">
        <div className="dashboard-header-info">
          {targetStatement ? (
            <h1>Go to your profile and fill out your target statement!</h1>
          ) : (
            <>
              <h1>
                Target Statement: I am looking for a {userInfo?.business_type || ''} company with {userInfo?.business_positive || ''} but{' '}
                {userInfo?.business_negative || ''} generating between{' '}
                ${Number(userInfo?.sde_min)?.toLocaleString() || ''} and ${Number(userInfo?.sde_max)?.toLocaleString() || ''} SDE!
              </h1>
            </>
          )}
        </div>
      </div>
      <div className="dashboard-main">
        <div className="dashboard-tabs">
          <button className={`tab-button ${currentView === 'Kanban' ? 'active' : ''}`} onClick={() => setCurrentView('Kanban')}>Kanban</button>
          <button className={`tab-button ${currentView === 'List' ? 'active' : ''}`} onClick={() => setCurrentView('List')}>List view</button>
        </div>
          {currentView === 'Kanban' ? (
            <KanbanBoard user={user} />
          ) : (
            <>
              <div className="dashboard-business-list-container">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td className='range-filter'>
                        <RangeFilterComponent
                          placeholderStart="Min Price"
                          placeholderEnd="Max Price"
                          onFilterChange={setPriceFilter}
                          startFieldName="startPrice"
                          endFieldName="endPrice"
                        />
                      </td>
                      <td>
                        <RangeFilterComponent
                          placeholderStart="SDE Min"
                          placeholderEnd="SDE Max"
                          onFilterChange={setSDEFilter}
                          startFieldName="startSDE"
                          endFieldName="endSDE"
                        />
                      </td>
                      <td className="filter-dropdown">
                        <MultiSelectPicklist
                          isMulti={false}
                          options={["Distribution", "Service", "Product"]}
                          onSelectionChange={setSelectedPicklistOptions}
                        />
                      </td>
                      <td className="filter-dropdown">
                        <MultiSelectPicklist
                          isMulti={true}
                          options={["None", "NDA not Submitted", "NDA Submitted", "Pre LOI Complete", "LOI Submitted", "Completed", "Not Interested"]}
                          onSelectionChange={setCurrentStepFilter}
                        />
                      </td>
                      <td>
                        <button className='button-styles' onClick={clearListComponentFilters}>Clear Filters</button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <ListComponent
                          user={user}
                          filters={{
                            startPrice: priceFilter.startPrice,
                            endPrice: priceFilter.endPrice,
                            startSDE: sdeFilter.startSDE,
                            endSDE: sdeFilter.endSDE,
                          }}
                          selectedPicklistOptions={selectedPicklistOptions}
                          currentStepFilter={currentStepFilter}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="dashboard-task-list-container">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <button className='button-styles' onClick={clearTaskListComponentFilters}>Clear Filters</button>
                      </td>
                      <td>
                        <MultiSelectPicklist
                          isMulti={false}
                          options={["PRELOI", "DUEDIL"]}
                          onSelectionChange={setCurrentTypeFilter}
                        />
                      </td>
                      <td>
                        <MultiSelectPicklist
                          isMulti={false}
                          options={["Not Started", "Started"]}
                          onSelectionChange={setTaskStatusFilter}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <TaskListComponent
                          user={user}
                          currentTypeFilter={currentTypeFilter}
                          taskStatusFilter={taskStatusFilter}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default Dashboard;
