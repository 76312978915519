import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BrokerDataService from "../../services/BrokerDataService";
import UserLimitDataService from "../../services/UserLimitDataService";
import "../../styles/profilePageStyles.css";
import "../../styles/Styles.css";
import { useNavigate } from "react-router-dom";

const BrokerProfile = ({user}) => {
  
  const userId = user.sub;
  const { id } = useParams();
  const [broker, setBroker] = useState(null); // State to store broker data
  const [editableBroker, setEditableBroker] = useState(null);
  const [updatedBroker, setUpdatedBroker] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  const navigate = useNavigate();

  // Fetch broker data from server on component mount
  useEffect(() => {
    const fetchBrokerData = async () => {
      try {
        const response = await BrokerDataService.getBrokerById(id, userId);
        const fetchedBroker = response.data;
        setBroker(fetchedBroker);
      } catch (error) {
        console.error("Error fetching broker data:", error);
      }
    };
    fetchBrokerData();
  }, [id]);

  const handleEdit = (id) => {
    if (broker && broker.id === id) {
      const brokerToEdit = { ...broker };
      setEditableBroker(brokerToEdit);
      setUpdatedBroker(brokerToEdit);
    }
  };
  
  const handleDelete = async (id) => {
    try {
      // Decrement the broker count first
      await UserLimitDataService.decrementBrokerCount(userId);
  
      // After decrementing the broker count, you can delete the broker
      await BrokerDataService.deleteBroker(id, userId);
  
      // Navigate to '/brokers-list'
      navigate('/brokers-list');
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data); // Show the error message in an alert
      }
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "phone_number") {
        const onlyDigits = value.replace(/\D/g, '');
        if (onlyDigits.length <= 10) {
            setUpdatedBroker({ ...updatedBroker, [name]: onlyDigits });
            // Reset error if the input is in the correct format
            setPhoneNumberError(null);
        } else {
            setPhoneNumberError("Please enter a valid 10-digit phone number.");
        }
    } else {
        setUpdatedBroker({ ...updatedBroker, [name]: value });
    }
};
  
const handleUpdate = async (id) => {
  try {
    await BrokerDataService.updateBroker(id, updatedBroker, userId);
    setBroker(updatedBroker); // Update the broker state with the updatedBroker object
    setEditableBroker(null);
  } catch (error) {
    console.error("Error updating broker:", error);
  }
};  

  if (!broker) {
    return <div>Loading...</div>;
  }

  function formatPhoneNumber(phoneNumber) {
    // Remove any non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Check if the phone number has 10 digits (typical in the US)
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }
  
    // If it doesn't have 10 digits, return the original input
    return phoneNumber;
  }

  return (
    <div className="app-container">
      {editableBroker ? (
            <div className="broker-profile-edit-container">
              <div className="broker-profile-edit-header">
                <input
                  type="text"
                  name="broker_name"
                  placeholder="Broker Name:"
                  className="broker-profile-edit-broker-name-input input"
                  value={updatedBroker.broker_name}
                  onChange={handleInputChange}
                />
                <div className="broker-profile-edit-buttons">
                  <svg
                    onClick={() => handleUpdate(broker.id)} 
                    type="button"
                    viewBox="0 0 1024 1024"
                    className="broker-profile-edit-save-button"
                  >
                    <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                  </svg>
                  <svg
                    onClick={() => setEditableBroker(null)} 
                    type="button"
                    viewBox="0 0 877.7142857142857 1024"
                    className="broker-profile-edit-cancel-button"
                  >
                    <path d="M749.714 510.286c0-62.286-18.286-120-49.714-168.571l-430.857 430.286c49.143 32 107.429 50.857 169.714 50.857 171.429 0 310.857-140 310.857-312.571zM178.857 681.143l431.429-430.857c-49.143-33.143-108-52-171.429-52-171.429 0-310.857 140-310.857 312 0 63.429 18.857 121.714 50.857 170.857zM877.714 510.286c0 243.429-196.571 440.571-438.857 440.571s-438.857-197.143-438.857-440.571c0-242.857 196.571-440 438.857-440s438.857 197.143 438.857 440z"></path>
                  </svg>
                </div>
              </div>
              <div className="broker-profile-edit-main">
                <div className="broker-profile-edit-info">
                  <div className="broker-profile-edit-fields">
                    <input
                      type="text"
                      name="broker_company"
                      placeholder="Company Name:"
                      className="broker-profile-edit-company-input input"
                      value={updatedBroker.broker_company}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      placeholder="(XXX)-XXX-XXXX"
                      className="broker-profile-edit-phone-number-input input"
                      name="phone_number"
                      value={formatPhoneNumber(updatedBroker.phone_number)}  // Use the formatting function
                      onChange={handleInputChange}
                      maxLength="14"  // Restrict input to 14 characters to accommodate formatted 10 digits
                    />
                    {phoneNumberError && <p style={{ color: 'red' }}>{phoneNumberError}</p>}
                    <input
                      type="text"
                      placeholder="Email:"
                      className="broker-profile-edit-email-input input"
                      name="email"
                      value={updatedBroker.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="broker-profile-edit-business-list">
                  <div className="broker-profile-edit-list-header">
                    <h1 className="broker-profile-edit-name Headers">Businesses</h1>
                  </div>
                  <div className="business-table">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Business Name</th>
                        <th>Selling Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {broker && broker.businesses && broker.businesses.length > 0 ? (
                        broker.businesses.map((business, index) => (
                          <tr key={business.id}>
                            <td>{index + 1}</td>
                            <td>
                              <a href={`/businessProfile/${business.id}`}>
                                {business.name}
                              </a>
                            </td>
                            <td>{business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No businesses added yet.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
            ) : (
      <div className="broker-profile-container">
        <div className="broker-profile-header">
          <h1 className="broker-profile-broker-name Headers">{broker ? `${broker.broker_name}` : "Loading..."}</h1>
          <div className="broker-profile-buttons">
            <svg viewBox="0 0 1024 1024" className="broker-profile-edit" onClick={() => handleEdit(broker.id)} type="button">
              <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
            </svg>
            <svg viewBox="0 0 1024 1024" className="broker-profile-delete" type="button" onClick={() => handleDelete(broker.id)}>
              <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
              <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
            </svg>
          </div>
        </div>
        <div className="broker-profile-main">
          <div className="broker-profile-info">
            <div className="broker-profile-fields">
              <span className="broker-profile-company Content">
                Broker Company: {broker.broker_company}
              </span>
              <span className="broker-profile-number Content">
                Phone Number: {formatPhoneNumber(broker.phone_number)}
              </span>
              <span className="broker-profile-email Content">Email: {broker.email}</span>
            </div>
          </div>
          <div className="broker-profile-business-list">
            <div className="broker-profile-list-header">
              <h1 className="broker-profile-name Headers">Businesses</h1>
              <svg onClick={() => navigate(`/add-business/${broker.id}`)}
                viewBox="0 0 1024 1024"
                className="broker-profile-add-business"
              >
                <path d="M992 384h-352v-352c0-17.672-14.328-32-32-32h-192c-17.672 0-32 14.328-32 32v352h-352c-17.672 0-32 14.328-32 32v192c0 17.672 14.328 32 32 32h352v352c0 17.672 14.328 32 32 32h192c17.672 0 32-14.328 32-32v-352h352c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32z"></path>
              </svg>
            </div>
            <div className="business-table Content">
            <table>
              <thead>
                      <tr>
                        <th>#</th>
                        <th>Business Name</th>
                        <th>Selling Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {broker && broker.businesses && broker.businesses.length > 0 ? (
                        broker.businesses.map((business, index) => (
                          <tr key={business.id}>
                            <td>{index + 1}</td>
                            <td>
                              <a href={`/businessProfile/${business.id}`}>
                                {business.name}
                              </a>
                            </td>
                            <td>{business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No businesses added yet.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}
export default BrokerProfile
