import React, { useState, useEffect } from "react";
import '../../styles/questionsList.css';
import { useParams } from "react-router-dom";
import BusinessDataService from "../../services/BusinessDataService";
import { useNavigate } from "react-router-dom";

const Questionslists = ({user}) => {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const userId = user.sub;
    const navigate = useNavigate();


    useEffect(() => {
        const fetchBusinessData = async () => {
          try {
            const response = await BusinessDataService.getBusinessById(id, userId);
            const fetchedBusiness = response.data;
            setBusiness(fetchedBusiness);
          } catch (error) {
            console.error("Error fetching business data:", error);
          }
        };
        fetchBusinessData();
      }, [id, userId]);
    
      if (!business) {
        return <div>Loading...</div>;
      }

      const businessName = business.name;
      const BusinessProfileLink = `/businessProfile/${business.id}`;

      const truncateText = (text, maxLength) => {
        if (!text) return '';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      };


  return (
    <div className="questions-list-container">
      <div className="questions-list-question-header">
        <h1 className="questions-list-header Headers">Questions for - <a href={BusinessProfileLink} style={{ color: '#295069' }}>{businessName}</a>
</h1>
        <div className="questions-list-button-container">
          <svg
            viewBox="0 0 804.5714285714286 1024"
            className="questions-list-add-button"
            onClick={() => {navigate(`/add-question/${business.id}`);}}
          >
            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
          </svg>
        </div>
      </div>
      <div className="questions-list-question-table">
      <div className="question-table Content">
      <table>
              <thead>
                      <tr>
                        <th>#</th>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                        {business && business.questions && business.questions.length > 0 ? (
                        business.questions.map((question, index) => (
                        <tr key={question.id}>
                          <td className="table-text-wrap">{index + 1}</td>
                          <td className="table-text-wrap">
                            <a href={`/question-profile/${question.id}`}>{truncateText(question.question_text, 250)}</a>
                          </td>
                          <td className="table-text-wrap">{truncateText(question.answer_text, 250)}</td>
                        </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No question added yet.</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
            </div>
      </div>
    </div>
  )
}

export default Questionslists
