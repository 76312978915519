import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <a href="/" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} style={{ color: "#295069", textDecoration: "none", fontSize: "17px", fontFamily: 'Open Sans, sans-serif', marginLeft: "1rem" }}>
      Log Out
    </a>
  );
};

export default LogoutButton;
