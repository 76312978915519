import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import AddBroker from './components/broker/AddBroker';
import BrokersList from './components/broker/BrokersList';
import EditBroker from './components/broker/EditBroker';
import Dashboard from './components/Login/Dashboard';
import AddBusiness from './components/business/AddBusiness';
import BusinessesList from './components/business/BusinessesList';
import EditBusiness from './components/business/EditBusiness';
import Navbar from './components/Navbar';
import BrokerProfile from './components/broker/BrokerProfile';
import AddOffer from './components/offer/AddOffer';
import OffersList from './components/offer/OffersList';
import EditOffer from './components/offer/EditOffer';
import BusinessProfile from './components/business/BusinessProfile';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './components/Login/Home';
import Loading from './components/Login/Loading';
import LoginDataService from './services/LoginDataService';
import SelectBroker from './components/broker/SelectBroker';
import OfferProfile from './components/offer/OfferProfile';
import UserProfile from './components/profile/UserProfile';
import TaskProfile from './components/Tasks/TasksProfile';
import AddTask from './components/Tasks/AddTask';
import CreateTaxYear from './components/business/CreateTaxYear';
import AddQuestion from './components/questions/AddQuestions';
import QuestionProfile from './components/questions/QuestionProfile';
import Questionslists from './components/questions/Questionslist';
import ChooseAnalysis from './components/Analysis/ChooseAnalysis';
import CreateSwot from './components/Analysis/CreateSwot';
import SwotProfile from './components/Analysis/SwotProfile';

const App = () => {
  const {user, isLoading, error, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const { sub, name, email } = user;
      LoginDataService.createUser(sub, name, email)
        .catch(err => console.error(err));
    }
  }, [isAuthenticated, user]);
  

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      { isAuthenticated ? (
        
        <Router>
          <div>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Dashboard user={user} />} />
              <Route exact path="/add-broker" element={<AddBroker user={user} />} />
              <Route exact path="/edit-broker/:id" element={<EditBroker />} />
              <Route exact path="/brokers-list" element={<BrokersList user={user}/>} />
              <Route exact path="/add-business/:id" element={<AddBusiness user={user}/>} />
              <Route exact path="/businesses-list" element={<BusinessesList user={user} />} />
              <Route exact path="/edit-business/:id" element={<EditBusiness />} />
              <Route exact path="/broker/:id" element={<BrokerProfile user={user} />} />
              <Route exact path="/businessProfile/:id" element={<BusinessProfile user={user} />} />
              <Route exact path="/add-offer/:id" element={<AddOffer user={user} />} />
              <Route exact path="/offers-list" element={<OffersList />} />
              <Route exact path="/edit-offer/:id" element={<EditOffer />} />
              <Route exact path="/select-broker" element={<SelectBroker user={user}/>} />
              <Route exact path="/offer/:id" element={<OfferProfile user={user}/>} />
              <Route exact path="/user-profile" element={<UserProfile user={user} /> } />
              <Route exact path="/task-profile/:id" element={<TaskProfile user={user}/> } />
              <Route exact path="/add-task/:businessId" element={<AddTask user={user} />} />
              <Route exact path="/add-taxYear/:businessId" element={<CreateTaxYear user={user}/>} />
              <Route exact path="/add-question/:businessId" element={<AddQuestion user={user}/>} />
              <Route exact path="/question-profile/:id" element={<QuestionProfile user={user}/>} />
              <Route exact path="/question-list/:id" element={<Questionslists user={user}/>} />
              <Route exact path="/choose-analysis/:businessId" element={<ChooseAnalysis user={user}/>} />
              <Route exact path="/create-swot/:businessId" element={<CreateSwot/>} />
              <Route exact path="/swot-profile/:id" element={<SwotProfile user={user}/>} />

            </Routes>
          </div>
        </Router>
      ) : (
        <Home />
      )}
    </div>
  );
};

export default App;
