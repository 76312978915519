import React, { useState, useEffect } from 'react';
import TaskDataService from '../../services/TaskDataService';
import { useNavigate } from 'react-router-dom';
import '../../styles/TaskStyles.css';

const TaskCheckList = ({ business, userId }) => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const businessId = business?.id;
    if (businessId) {
      setIsLoading(true);
      TaskDataService.getTaskById(businessId, userId)
        .then((data) => {
          setTasks(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [business?.id, userId]);

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const taskData = { taskName: newTask, status: 'Started', business_id: business.id, user_id: business.user_id };
      TaskDataService.createTask(taskData)
        .then((response) => {
          const taskId = response.taskId; // Extract taskId from the response
          console.log('Task created successfully', taskId);
          navigate(`/task-profile/${taskId}`); // Use taskId to navigate
        })
        .catch((error) => {
          console.error('Error creating task:', error);
        });
  
      setNewTask('');
    }
  };
  

  const handleToggleTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].status = updatedTasks[index].status === 'Completed' ? 'Started' : 'Completed';
    setTasks(updatedTasks);

    const taskToUpdate = updatedTasks[index];
    TaskDataService.updateTasks(taskToUpdate, userId)
      .then(() => {
        console.log('Task updated successfully');
        // You can update the UI or fetch the tasks again if needed
      })
      .catch((error) => {
        console.error('Error updating task:', error);
      });
  };

  return (
    <div>
      <h1 className='Headers' color='#295069'>Task</h1>
      <div>
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          className="input"
        />
        <button className="buttons Content" onClick={handleAddTask}>Add Task</button>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : tasks?.length > 0 ? (
        <ul>
          {tasks.map((task, index) => (
            <li key={index}>
              <input
                type="checkbox"
                checked={task.status === 'Completed'}
                onChange={() => handleToggleTask(index)}
              />
              <span
                style={{
                  textDecoration: task.status === 'Completed' ? 'line-through' : 'none',
                }}
              >
                <a className='Content' href={`/task-profile/${task.id}`}>{task.task_name}</a>
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <div>No tasks found.</div>
      )}
    </div>
  );
};

export default TaskCheckList;
