import React, { useState, useEffect }  from 'react'
import '../../styles/createswot.css';
import '../../styles/swotprofile.css'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AnalysisDataService from '../../services/AnalysisDataService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SwotProfile = (props) => {
    const [editableSWOT, setEditableSWOT] = useState(null);
    const [analysis, setAnalysis] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [strengths, setStrengths] = useState("");
    const [weaknesses, setWeaknesses] = useState("");
    const [opportunities, setOpportunities] = useState("");
    const [threats, setThreats] = useState("");
    const [status, setStatus] = useState('');


    
    useEffect(() => {
      const fetchSwotData = async () => {
        try {
          const response = await AnalysisDataService.getAnalysisById(id);
          const fetchedAnalysis = response.data;
          setAnalysis(fetchedAnalysis);
          // Assuming fetchedAnalysis contains fields strengths, weaknesses, opportunities, threats
          setStatus(fetchedAnalysis.metadata.status || '');
          setStrengths(fetchedAnalysis.strengths || "");
          setWeaknesses(fetchedAnalysis.weaknesses || "");
          setOpportunities(fetchedAnalysis.opportunities || "");
          setThreats(fetchedAnalysis.threats || "");
        } catch (error) {
          console.error("Error fetching Analysis data:", error);
        }
      };
      fetchSwotData();
    }, [id]);

      const handleEdit = () => {
        setEditableSWOT(true);
      };

      const handleCancel = () => {
        setEditableSWOT(null);
      };

      const handleSave = async () => {
        // Construct the payload
        const payload = {
          status, 
          strengths,
          weaknesses,
          opportunities,
          threats
        };
      
        try {
          await AnalysisDataService.updateSwot(id, payload); // 'id' here is the analysis_id
          // Fetch updated data after successful save
          const updatedResponse = await AnalysisDataService.getAnalysisById(id);
          const updatedAnalysis = updatedResponse.data;
          setAnalysis(updatedAnalysis);
          // Assuming updatedAnalysis contains fields strengths, weaknesses, opportunities, threats
          setStatus(updatedAnalysis.metadata.status || '');
          setStrengths(updatedAnalysis.strengths || "");
          setWeaknesses(updatedAnalysis.weaknesses || "");
          setOpportunities(updatedAnalysis.opportunities || "");
          setThreats(updatedAnalysis.threats || "");
          setEditableSWOT(false); // Exit edit mode on success
        } catch (error) {
          console.error('Error saving SWOT Analysis:', error);
          alert('Failed to save SWOT Analysis');
        }
      };
            


      const handleDelete = async (id) => {
        try {
          await AnalysisDataService.deleteSwot(id);
          navigate(`/businessProfile/${analysis.business.id}`);
        } catch (error) {
          console.error(error);
          if (error.response && error.response.data) {
            alert(error.response.data); // Show the error message in an alert
          }
        }
      };

      const handleStrengthsChange = (content, delta, source, editor) => {
        setStrengths(content); // sets the HTML content from the editor
      };

      const handleWeaknessesChange = (content, delta, source, editor) => {
        setWeaknesses(content); // sets the HTML content from the editor
      };

      const handleOpportunitiesChange = (content, delta, source, editor) => {
        setOpportunities(content); // sets the HTML content from the editor
      };

      const handleThreatsChange = (content, delta, source, editor) => {
        setThreats(content); // sets the HTML content from the editor
      };
      
  
      if (!analysis) {
        return <div>Loading...</div>;
      }

      const businessName = analysis.business.name;
      const BusinessProfileLink = `/businessProfile/${analysis.business.id}`;



  return (
    <div>
        {editableSWOT ? (
            <div className="swot-analysis-container">
            <div className="swot-analysis-header">
              <h1 className="swot-analysis-swot-analysis Headers">SWOT Analysis</h1>
            </div>
            <div className="swot-analysis-container1">
              <span className="swot-analysis-text Content">
                <span>
                  Chat GPT Prompt Example: Perform a comprehensive business analysis
                  for [insert business description] including a SWOT analysis to
                  identify strengths, weaknesses, opportunities, and threats.
                </span>
                <br></br>
              </span>
              <svg viewBox="0 0 1024 1024" className="swot-analysis-icon" onClick={handleCancel}>
                <path d="M512 854q140 0 241-101t101-241q0-48-21-110t-51-100l-480 480q90 72 210 72zM170 512q0 48 21 110t51 100l480-480q-90-72-210-72-140 0-241 101t-101 241zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
              </svg>
            </div>
            <div className="swot-analysis-status-container">
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="Choose">Choose Status</option>
                <option value="started">Started</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
            <div className="swot-analysis-grid">
              <div className="swot-analysis-grid-item">
              <h2 className="swot-analysis-grid-title">Strengths</h2>
                <ReactQuill
                  theme="snow"
                  value={strengths}
                  onChange={handleStrengthsChange}
                />
              </div>
              <div className="swot-analysis-grid-item">
              <h2 className="swot-analysis-grid-title">Weaknesses</h2>
                <ReactQuill
                  theme="snow"
                  value={weaknesses}
                  onChange={handleWeaknessesChange}
                />
              </div>
              <div className="swot-analysis-grid-item">
              <h2 className="swot-analysis-grid-title">Opportunities</h2>
                <ReactQuill
                  theme="snow"
                  value={opportunities}
                  onChange={handleOpportunitiesChange}
                />
              </div>
              <div className="swot-analysis-grid-item">
              <h2 className="swot-analysis-grid-title">Threats</h2>
                <ReactQuill
                  theme="snow"
                  value={threats}
                  onChange={handleThreatsChange}
                />
              </div>
            </div>
            <div className="swot-analysis-button-container">
              <button
                name="Add Question"
                type="button"
                className="swot-analysis-save-swot Content button"
                onClick={() => handleSave()}
              >
                Save SWOT
              </button>
            </div>
          </div>
        ) : ( 
    <div className="swot-analysis-profile-container">
      <div className="swot-analysis-profile-header">
        <h1 className="swot-analysis-profile-swot-analysis Headers">
          SWOT Analysis
        </h1>
      </div>
      <div className="swot-analysis-profile-info-container">
        <h1 className="swot-analysis-profile-title Headers">{<a href={BusinessProfileLink}>{businessName}</a>}</h1>
        <div className="swot-analysis-profile-buttons" >
          <svg
            viewBox="0 0 1024 1024"
            className="swot-analysis-profile-edit-button"
            onClick={() => handleEdit()}
          >
            <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
          </svg>
          <svg
            viewBox="0 0 1024 1024"
            className="swot-analysis-profile-delete-button"
            onClick={() => handleDelete(id)}
          >
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      </div>
      <div className="swot-analysis-profile-status-container Content">
        <span>
            Status: {analysis.metadata.status}
        </span>
      </div>
      <div className="swot-analysis-grid">
  <div className="swot-analysis-grid-item">
    <h1 className="swot-analysis-grid-title Headers">Strengths</h1>
    <div
      className="swot-analysis-grid-value Content"
      dangerouslySetInnerHTML={{ __html: strengths }}
    />
  </div>
  <div className="swot-analysis-grid-item">
    <h1 className="swot-analysis-grid-title Headers">Weaknesses</h1>
    <div
      className="swot-analysis-grid-value Content"
      dangerouslySetInnerHTML={{ __html: weaknesses }}
    />
  </div>
  <div className="swot-analysis-grid-item">
    <h1 className="swot-analysis-grid-title Headers">Opportunities</h1>
    <div
      className="swot-analysis-grid-value Content"
      dangerouslySetInnerHTML={{ __html: opportunities }}
    />
  </div>
  <div className="swot-analysis-grid-item">
    <h1 className="swot-analysis-grid-title Headers">Threats</h1>
    <div
      className="swot-analysis-grid-value Content"
      dangerouslySetInnerHTML={{ __html: threats }}
    />
  </div>
</div>
    </div>
    )}
    </div>
  )
}

export default SwotProfile
