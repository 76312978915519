import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditBusiness = ({ businesses, updateBusiness }) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [business, setBusiness] = useState({
    Name: "",
    SellingPrice: "",
  });

  useEffect(() => {
    const selectedBusiness = businesses.find((business) => business.id === parseInt(id));
    setBusiness(selectedBusiness);
  }, [businesses, id]);

  const { Name, SellingPrice } = business;

  const onInputChange = (e) => {
    setBusiness({ ...business, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateBusiness(business);
    navigate("/businesses-list"); // Navigate to Broker list page
  };

  return (
    <div>
      <h1>Edit Business</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="Name">Name</label>
        <input
          type="text"
          name="Name"
          value={Name}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <label htmlFor="SellingPrice">SellingPrice</label>
        <input
          type="text"
          name="SellingPrice"
          value={SellingPrice}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <button type="submit">Update Business</button>
      </form>
    </div>
  );
};

export default EditBusiness;