import React from "react";
//import { Link } from "react-router-dom";
import LoginButton from "./Login/LoginButton";

const Hometabs = () => {
  return (
    <nav style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#333", padding: "1rem" }}>
      <ul style={{ display: "flex", justifyContent: "space-between", listStyle: "none", padding: "0" }}>
        <li style={{ marginLeft: "1rem" }}><LoginButton /></li>

      </ul>
    </nav>
  );
};

export default Hometabs;
