import React, { useState, useEffect } from "react";
import BrokerDataService from "../../services/BrokerDataService";
import UserLimitDataService from "../../services/UserLimitDataService";
import "../../styles/tableStyles.css"; // Update the import path based on your file structure
import "../../styles/BrokerList.css";
import "../../styles/Styles.css";
import {useNavigate  } from 'react-router-dom';

const BrokersList = ({user}) => {

  const [brokers, setBrokers] = useState([]);
  const [editableBroker, setEditableBroker] = useState(null);
  const [updatedBroker, setUpdatedBroker] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [phoneError, setPhoneError] = useState("");


  const navigate = useNavigate(); // Add useNavigate hook
  const userId = user.sub

  async function getBrokers(userId) {
    const res = await fetch(`/brokers/${userId}`);
    const brokerArray = await res.json();
    setBrokers(brokerArray);
  }
  

  useEffect(() => {
    getBrokers(userId);
  }, [userId]);

  const handleEdit = (id) => {
    const brokerToEdit = brokers.find(broker => broker.id === id);
    setEditableBroker(JSON.parse(JSON.stringify(brokerToEdit))); // Use deep copy to create a new object
    setUpdatedBroker(JSON.parse(JSON.stringify(brokerToEdit))); // Use deep copy to create a new object
  };

  const handleCancel = () => {
    setEditableBroker(null);
  };

  const handleSave = (id) => {
    BrokerDataService.updateBroker(id, updatedBroker, userId)
      .then(response => {
        if (response.status === 200) {
          // Update the brokers state with the updated broker information
          setBrokers(prevBrokers => prevBrokers.map(broker => {
            if (broker.id === id) {
              return { ...broker, ...updatedBroker };
            }
            return broker;
          }));
        } else {
          alert('Failed to update broker');
        }
        setEditableBroker(null);
      })
      .catch(error => {
        console.error(error);
        alert('Failed to update broker');
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "phone_number") {
      const cleaned = value.replace(/\D/g, '');
  
      if (cleaned.length < 10) {
        setPhoneError("Phone number is too short.");
      } else if (cleaned.length > 10) {
        setPhoneError("Phone number is too long.");
      } else {
        setPhoneError("");
      }
    }
  
    setUpdatedBroker({ ...updatedBroker, [name]: value });
  };  
  

  const handleDelete = async (id) => {
    try {
      // After decrementing the broker count, you can delete the broker
      await BrokerDataService.deleteBroker(id, userId);

      // Decrement the broker count first
      await UserLimitDataService.decrementBrokerCount(userId);
      setBrokers((prevBrokers) =>
        prevBrokers.filter((broker) => broker.id !== id)
      );
    } catch (error) {
      console.log(error.response.data);
      alert(error.response.data); // Show the error message in an alert
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Remove unnecessary toString() conversion
  };
  
  const handleSearchClick = () => {
    if(searchTerm){
    BrokerDataService.searchBrokers(searchTerm, userId)
      .then((response) => {
        setBrokers(response.data);
      })
      .catch((error) => {
        console.error("Error searching brokers:", error);
      });
    } else{
      getBrokers(userId);
    }
  };
  
  function formatPhoneNumber(phoneNumber) {
    // Remove any non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Check if the phone number has 10 digits (typical in the US)
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }
  
    // If it doesn't have 10 digits, return the original input
    return phoneNumber;
  }


  return (
    <div className="broker-list-container">
      <div className="broker-list-broker-header">
        <h1 className="broker-list-header Headers">Brokers</h1>
        <div className="broker-list-container1">
          <svg
            viewBox="0 0 804.5714285714286 1024"
            className="broker-list-add-button"
            onClick={() => {navigate("/add-broker");}}
          >
            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
          </svg>
          <input
            type="text"
            placeholder="Search for Broker ..."
            className="broker-list-search-input input Content"
            onChange={handleSearch}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
              handleSearchClick();
            }
          }}
          />
          <svg
            viewBox="0 0 950.8571428571428 1024"
            className="broker-list-search-button"
            onClick={handleSearchClick}
          >
            <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
          </svg>
        </div>
      </div>
      <div className="broker-list-broker-table">
      <div className="table-container Content" >
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Broker Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(brokers) &&
              brokers.map((broker, index) => (
                <tr key={broker.id}>
                  <td>{index + 1}</td>
                  <td>
                    {editableBroker && editableBroker.id === broker.id ? (
                      <input
                        type="text"
                        name="broker_name"
                        value={updatedBroker.broker_name || ""}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <a href={`/broker/${broker.id}`}>{broker.broker_name}</a>
                    )}
                  </td>
                  <td>
                    {editableBroker && editableBroker.id === broker.id ? (
                      <>
                        <input
                          type="text"
                          name="phone_number"
                          value={updatedBroker.phone_number || ""}
                          onChange={handleInputChange}
                        />
                        {phoneError && <div className="error-message">{phoneError}</div>}
                      </>
                    ) : (
                      formatPhoneNumber(broker.phone_number)
                    )}
                  </td>
                  <td>
                    {editableBroker && editableBroker.id === broker.id ? (
                      <input
                        type="text"
                        name="email"
                        value={updatedBroker.email || ""}
                        onChange={handleInputChange}
                      />
                    ) : (
                      broker.email
                    )}
                  </td>
                  <td>
                    {editableBroker && editableBroker.id === broker.id ? (
                      <>
                        <button onClick={() => handleSave(broker.id)} disabled={phoneError !== ""}>Save</button>
                        <button onClick={handleCancel}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleEdit(broker.id)}>Edit</button>
                        <button onClick={() => handleDelete(broker.id)}>
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}
export default BrokersList