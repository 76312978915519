import axios from "axios";

const UserLimitDataService = {

    checkBrokerLimit: (userId) => {
      return axios.get("/UserLimits", { params: { userId } })
        .then(response => response.data)
        .catch(error => {
          console.error("Error checking broker limit", error);
          throw error;
        });
    },

    checkBusinessLimit: (userId) => {
      return axios.get("/BusinessLimits", { params: { userId } })
        .then(response => response.data)
        .catch(error => {
          console.error("Error checking business limit", error);
          throw error;
        });
    },

    incrementBrokerCount: (userId) => {
      return axios.post("/incrementBrokerCount", { userId })
        .then(response => response.data)
        .catch(error => {
          console.error("Error incrementing broker count", error);
          throw error;
        });
    },

    incrementBusinessCount: (userId) => {
      return axios.post("/incrementBusinessCount", { userId })
        .then(response => response.data)
        .catch(error => {
          console.error("Error incrementing business count", error);
          throw error;
        });
    }, 
    decrementBrokerCount: (userId) => {
      return axios.post("/decrementBrokerCount", { userId })
          .then(response => response.data)
          .catch(error => {
              console.error("Error decrementing broker count", error);
              throw error;
          });
    }, 
    decrementBusinessCount: (userId) => {
      return axios.post("/decrementBusinessCount", { userId })
          .then(response => response.data)
          .catch(error => {
              console.error("Error decrementing business count", error);
              throw error;
          });
  }, 

  triggerEmailUpdate: () => {
    return axios.post("/trigger-email-update")
      .then(response => response.data)
      .catch(error => {
        console.error("Error triggering email update", error);
        throw error;
      });
  },
};

export default UserLimitDataService;
