import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from React 18
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

// Get the root DOM node where the React app will be mounted
const container = document.getElementById("root");
const root = createRoot(container); // Create a root using the container

// Render your App component within the root, wrapped with Auth0Provider
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-nizrpi714nv5usb1.us.auth0.com"
      clientId="SxSmzsOmUI0Jp6Mj7OXovwjX4rEabemM"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
