import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TaskDataService from "../../services/TaskDataService";
import "../../styles/TaskProfile.css";

const TaskProfile = ({user}) => {

  const { id } = useParams();
  const [task, setTask] = useState(null); // State to store broker data
  const [editableTask, seteditableTask] = useState(null);
  const [updatedTask, setupdatedTask] = useState({});
  const navigate = useNavigate();
  const userId = user.sub;

  // Fetch task data from server on component mount
  useEffect(() => {
    if (id) {
      TaskDataService.getTask(id, userId)
        .then((response) => {
          console.log("Response:", response); // Log the response object
  
          if (response) {
            console.log("business_id:", response.business_id);
            const fetchedTask = response;
            setTask(fetchedTask);
          } else {
            console.log("Response is null or undefined");
          }
        })
        .catch((error) => {
          console.error("Error fetching task data:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (task) {
      setupdatedTask({
        task_name: task.task_name || '',
        status: task.status || '',
        due_date: task.due_date || '',
        start_date: task.start_date || '',
        more_info: task.more_info || ''
      });
    }
  }, [task]);



  const handleEdit = (id) => {
    if (task && task.id === id) {
      const taskToEdit = { ...task };
      seteditableTask(taskToEdit);
      setupdatedTask(taskToEdit);
    }
  };
  
  const handleDelete = async (id) => {
    try {
      await TaskDataService.deleteTask(id, userId);
      navigate(`/businessProfile/${task.business_id}`);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data); // Show the error message in an alert
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return 'NA';
    }
  
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedTask({ ...updatedTask, [name]: value });
  };
  
const handleUpdate = async (id) => {
  try {
    await TaskDataService.updateTaskInfo(id, updatedTask, userId);
    setTask(updatedTask); // Update the task state with the updatedTask object
    seteditableTask(null);
  } catch (error) {
    console.error("Error updating task:", error);
  }
}; 


  return (
    <div>
      {editableTask ? (
        <div className="task-profile-edit-container">
        <div className="task-profile-edit-header">
          <div className="task-profile-edit-main-information">
            <input
              type="text"
              placeholder="Task Name:"
              className="task-profile-edit-task-name input Content"
              value={updatedTask.task_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="task-profile-edit-buttons">
            <svg
              viewBox="0 0 1024 1024"
              className="task-profile-edit-save-button"
              onClick={() => handleUpdate(task.id)}
            >
              <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
            <svg
              viewBox="0 0 1024 1024"
              className="task-profile-edit-cancel-button"
              onClick={() => seteditableTask(null)}
            >
              <path d="M512 854q140 0 241-101t101-241q0-114-74-210l-478 478q96 74 210 74zM170 512q0 114 74 210l478-478q-96-74-210-74-140 0-241 101t-101 241zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
            </svg>
          </div>
        </div>
        <div className="task-profile-edit-main">
          <div className="task-profile-edit-general-information">
            <div className="task-profile-edit-fields">
              <select
                name="status"
                value={updatedTask.status}
                onChange={handleInputChange}
                className="task-profile-edit-status input Content"
                >
                <option value="Not Started">Not Started</option>
                <option value="Started">Started</option>
                <option value="Completed">Completed</option>
              </select>
              <input
                type="date"
                className="task-profile-edit-start-date input Content"
                name="start_date"
                value={updatedTask.start_date || ''}
                onChange={handleInputChange}
              />
              <input
                type="date"
                className="task-profile-edit-due-date input Content"
                name="due_date"
                value={updatedTask.due_date || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="task-profile-edit-more-information">
            <div className="task-profile-edit-header-container">
              <h1 className="task-profile-edit-title Headers">
                More Information
              </h1>
            </div>
            <div className="task-profile-edit-content">
              <textarea
                name="more_info"
                placeholder="More Information..."
                className="task-profile-edit-more-info textarea Content"
                value={updatedTask.more_info || ''}  
                onChange={handleInputChange} 
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <div className="task-profile-container">
        <div className="task-profile-header">
          <div className="task-profile-main-information">
            <h1 className="task-profile-task-name Headers">{task ? task.task_name : "NA"}</h1>
          </div>
          <div className="task-profile-buttons">
            <svg onClick={() => handleEdit(task.id)} viewBox="0 0 1024 1024" className="task-profile-edit-button">
              <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
            </svg>
            <svg onClick={() => handleDelete(task.id)} viewBox="0 0 1024 1024" className="task-profile-delete-button">
              <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
              <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
            </svg>
          </div>
        </div>
        <div className="task-profile-main">
          <div className="task-profile-general-information">
            <div className="task-profile-fields">
              <span className="task-profile-status Content">Status: {task ? task.status : "NA"}</span>
              <span className="task-profile-start-date Content">Start Date: {formatDate(task ? task.start_date : null)}</span>
              <span className="task-profile-due-date Content">Due Date: {formatDate(task ? task.due_date : null)}</span>
              <h3 className="task-profile-link Content">{task && task.business_id ? (
                  <a href={`/businessProfile/${task.business_id}`}>Back to Business profile</a>
                ) : (
                  "NA"
                )}</h3>
            </div>
          </div>
          <div className="task-profile-more-information">
            <div className="task-profile-header-container">
              <h1 className="task-profile-title Headers">More Information</h1>
            </div>
            <div className="task-profile-content">
              <p className="task-profile-textarea">
              {task && task.more_info ? task.more_info : "Add more Information..."}
            </p>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default TaskProfile
