import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OfferDataService from "../../services/OfferDataService";
import { useNavigate } from "react-router-dom";
import '../../styles/OfferProfile.css';

const OfferProfile = ({user}) => {

  const { id } = useParams();
  const [offer, setoffer] = useState(null); // State to store broker data
  //const [editableOffer, setEditableOffer] = useState(null);
  //const [updatedOffer, setUpdatedOffer] = useState({});
  const navigate = useNavigate();
  const userId = user.sub;

    // Fetch broker data from server on component mount
    useEffect(() => {
      const fetchOfferData = async () => {
        try {
          const response = await OfferDataService.getOfferById(id, userId);
          const fetchedOffer = response.data;
          setoffer(fetchedOffer);
        } catch (error) {
          console.error("Error fetching Offer data:", error);
        }
      };
      fetchOfferData();
    }, [id, userId]);
  /*
    const handleEdit = (id) => {
      if (offer && offer.id === id) {
        const offerToEdit = { ...offer };
        setEditableOffer(offerToEdit);
        setUpdatedOffer(offerToEdit);
      }
    };
    */

    const handleDelete = async (id) => {
      try {
        await OfferDataService.deleteOffer(id, userId);
        navigate(`/businessProfile/${offer.business.id}`);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          alert(error.response.data); // Show the error message in an alert
        }
      }
    };

    if (!offer) {
      return <div>Loading...</div>;
    }
    
  /*
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setUpdatedOffer({ ...updatedOffer, [name]: value });
    };
    
  const handleUpdate = async (id) => {
    try {
      await OfferDataService.updateOffer(id, updatedOffer);
      setoffer(updatedOffer); // Update the offer state with the updatedOffer object
      setEditableOffer(null);
    } catch (error) {
      console.error("Error updating Offer:", error);
    }
  };
  */
    if (!offer) {
      return <div>Loading...</div>;
    }

    const purchasePriceDisplay = offer.purchase_price ? `$${offer.purchase_price.toLocaleString()}` : 'N/A';
    const bankFeeDisplay = `$${(offer.bank_fee ?? 0).toLocaleString()}`;
    const workingCapitalDisplay = `$${(offer.working_capital ?? 0).toLocaleString()}`;
    const additionalFeesDisplay = `$${(offer.additional_fees ?? 0).toLocaleString()}`;
    const dueDiligenceDisplay = `$${(offer.due_diligence ?? 0).toLocaleString()}`;
    const sbaGuaranteeFeeDisplay = `$${(offer.sba_guarantee_fee ?? 0).toLocaleString()}`;
    const sbaLoanTotalDisplay = `$${(offer.sba_loan_total ?? 0).toLocaleString()}`;
    const downPaymentDisplay = `$${(offer.downpayment ?? 0).toLocaleString()}`;
    const sbaMonthlyPaymentDisplay = offer.sba_monthly_payment ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(offer.sba_monthly_payment) : '$0';
    const sellerFinancingDisplay = `$${(offer.seller_financing ?? 0).toLocaleString()}`;
    const sellerMonthlyPaymentDisplay = offer.seller_monthly_payment ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(offer.seller_monthly_payment) : '$0';
    const concurrentDSCRDsiaply = offer.concurrent_dscr; 
    const DelayedDSCRDisplay = offer.delay_dscr; 
    const SbaIR = `${(offer.sba_ir * 100).toFixed(2)}%`;
    const DownpaymentPercentString = `${offer.downpayment_percent}%`;
    const SellerInterestRate = `${Number(offer.seller_ir).toFixed(2)}%`;
    const SellerFinancingPercent = `${offer.sellerfinancing_percent}%`;
    const BusinessProfileLink = `/businessProfile/${offer.business.id}`;
    const businessName = offer.business.name;
    const netProfit = offer.net_profit? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(offer.net_profit) : '$0';
    const cashflowConcurrent = offer.cashflow_concurrent? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(offer.cashflow_concurrent) : '$0';
    const CashflowDelayed = offer.cashflow_delay? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(offer.cashflow_delay) : '$0';
    const numericValueOfCashflowConcurrent = parseFloat(cashflowConcurrent.replace(/[^0-9.]/g, ''));
    const AnnualCashflow = numericValueOfCashflowConcurrent * 12;
    const numericValueOfCashflowDelay = parseFloat(CashflowDelayed.replace(/[^0-9.]/g, ''));
    const AnnualCashflowDelay = numericValueOfCashflowDelay * 12;
    const numericValueOfDownPayment = parseFloat(downPaymentDisplay.replace(/[^0-9.]/g, ''));



    const CashonCashConcurrent = () => {
      const CoC = ((AnnualCashflow/parseFloat(offer.sba_loan_total))*100);
      return CoC;
    }
  
    const CashonCashDelay = () => {
      const CoC = ((AnnualCashflowDelay/parseFloat(offer.sba_loan_total))*100);
      return CoC;
    }


  return (
    <div className="offer-profile-container">
      <div className="offer-profile-header">
        <div className="offer-profile-highlights">
          <span className="offer-profile-purchase-price Content">
            Purchase Price: {purchasePriceDisplay}
          </span>
          <span className="offer-profile-net-profit Content">
            Net Operating Income: {netProfit}
          </span>
        </div>
        <div className="offer-profile-buttons">
          <svg viewBox="0 0 1024 1024" className="offer-profile-delete-button" onClick={() => handleDelete(offer.id)}>
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      </div>
      <div className="offer-profile-main">
        <div className="offer-profile-fees-and-sba">
          <div className="offer-profile-header-container">
            <h1 className="offer-profile-fees-header Headers">Fees</h1>
          </div>
          <div className="offer-profile-fee-fields">
            <div className="offer-profile-fee-left">
              <span className="offer-profile-bank-fee Content">Bank Fee: {bankFeeDisplay}</span>
              <span className="offer-profile-working-capital Content">
                Working Capital: {workingCapitalDisplay}
              </span>
              <span className="offer-profile-additional-fees Content">
                Additional Fees: {additionalFeesDisplay}
              </span>
            </div>
            <div className="offer-profile-fee-right">
              <span className="offer-profile-due-diligence Content">
                Due Diligence: {dueDiligenceDisplay}
              </span>
              <span className="offer-profile-sba-guarantee-fee Content">
                SBA Guarantee Fee: {sbaGuaranteeFeeDisplay}
              </span>
            </div>
          </div>
          <div className="offer-profile-sba-container">
            <h1 className="offer-profile-sba-header Headers">SBA Loan</h1>
          </div>
          <div className="offer-profile-sba-fields">
            <div className="offer-profile-sba-left">
              <span className="offer-profile-sba-loan Content">SBA Loan: {sbaLoanTotalDisplay}</span>
              <span className="offer-profile-sba-ir Content">
                SBA Interest Rate: {SbaIR}
              </span>
              <span className="offer-profile-sba-mp Content">
                SBA Monthly Payment: {sbaMonthlyPaymentDisplay}
              </span>
            </div>
            <div className="offer-profile-sba-right">
              <span className="offer-profile-down-payment Content">
                Down Payment: {downPaymentDisplay}
              </span>
              <span className="offer-profile-down-payment-percentage Content">
                Down Payment Percentage: {DownpaymentPercentString} 
              </span>
            </div>
          </div>
        </div>
        <div className="offer-profile-seller-financing Content">
        <div className="offer-profile-seller-container">
            <h1 className="offer-profile-text1 Headers">Cashflow</h1>
          </div>
          <table>
                <tbody>
                  <tr>
                    <th>Financing Terms</th>
                    <th>Monthly Cashflow</th>
                    <th>Annual Cashflow</th>
                    <th>DSCR</th>
                    <th>CoC</th>
                  </tr>
                  <tr>
                  <td className="financing-terms">Concurrent</td>
                    <td>{cashflowConcurrent.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    <td>{!isNaN(AnnualCashflow) ? AnnualCashflow.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : 'Error'}</td>
                    <td>{concurrentDSCRDsiaply}</td>
                    <td>{CashonCashConcurrent().toFixed(2)}%</td>
                  </tr>
                  <tr>
                  <td className="financing-terms">Delay</td>
                    <td>{CashflowDelayed}</td>
                    <td>{!isNaN(AnnualCashflowDelay) ? AnnualCashflowDelay.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : 'Error'}</td>
                    <td>{DelayedDSCRDisplay}</td>
                    <td>{CashonCashDelay().toFixed(2)}%</td>
                  </tr>
                </tbody>
              </table>
          <div className="offer-profile-seller-container">
            <h1 className="offer-profile-text1 Headers">Seller Financing</h1>
          </div>
          <div className="offer-profile-seller-fields">
            <div className="offer-profile-seller-left">
              <span className="offer-profile-seller-financing1 Content">
                Seller Financing: {sellerFinancingDisplay}
              </span>
              <span className="offer-profile-seller-interest-rate Content">
                Seller Interest Rate: {SellerInterestRate}
              </span>
              <span className="offer-profile-text2 Content">
              <div>
                  Business Name: {<a href={BusinessProfileLink}>{businessName}</a>}
              </div>

              </span>
            </div>
            <div className="offer-profile-container1">
              <span className="offer-profile-down-payment-percentage1 Content">
                Seller Financing Percentage: {SellerFinancingPercent}
              </span>
              <span className="offer-profile-seller-monthly-payment Content">
                Seller Monthly Payment: {sellerMonthlyPaymentDisplay}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferProfile
