import React, { useState } from "react";
import { Link } from "react-router-dom";

const OffersList = ({ offers, deleteOffer, updateOffer }) => {
  const [editableOffer, setEditableOffer] = useState(null);
  const [updatedPurchasingPrice, setUpdatedPurchasingPrice] = useState("");
  const [updatedSellersNote, setUpdatedSellersNote] = useState("");

  const handleEdit = (offer) => {
    setEditableOffer(offer);
    setUpdatedPurchasingPrice(offer.PurchasingPrice);
    setUpdatedSellersNote(offer.SellersNote);
  };

  const handleCancel = () => {
    setEditableOffer(null);
  };

  const handleSave = () => {
    if (editableOffer) {
      const updatedOffer = {
        ...editableOffer,
        PurchasingPrice: updatedPurchasingPrice,
        SellersNote: updatedSellersNote,
      };
      console.log("Saving offer:", updatedOffer);
      updateOffer(updatedOffer);
      setEditableOffer(null);
    }
  };

  return (
    <div>
      <h1>Offers</h1>
      <table>
        <thead>
          <tr>
            <th>Purchasing Price</th>
            <th>Sellers Note</th>
            <th>Actions</th>
          </tr>
        </thead>
        {offers && offers.length > 0 && ( // Add conditional check here
          <tbody>
            {offers.map((offer) => (
              <tr key={offer.id}>
                <td>
                  {editableOffer && editableOffer.id === offer.id ? (
                    <input
                      type="text"
                      value={updatedPurchasingPrice}
                      onChange={(e) =>
                        setUpdatedPurchasingPrice(e.target.value)
                      }
                    />
                  ) : (
                    offer.PurchasingPrice
                  )}
                </td>
                <td>
                  {editableOffer && editableOffer.id === offer.id ? (
                    <input
                      type="text"
                      value={updatedSellersNote}
                      onChange={(e) => setUpdatedSellersNote(e.target.value)}
                    />
                  ) : (
                    offer.SellersNote
                  )}
                </td>
                <td>
                  {editableOffer && editableOffer.id === offer.id ? (
                    <React.Fragment>
                      <button onClick={handleSave}>Save</button>
                      <button onClick={handleCancel}>Cancel</button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <button onClick={() => handleEdit(offer)}>Edit</button>
                      <button onClick={() => deleteOffer(offer.id)}>
                        Delete
                      </button>
                    </React.Fragment>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <Link to="/add-offer" style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        Add Offer
      </Link>
    </div>
  );
};

export default OffersList;
