import React, { useState, useEffect } from 'react';

const ListComponent = ({ user, currentTypeFilter, taskStatusFilter }) => {
    const [tasks, setTasks] = useState([]);
    const userId = user.sub;

    async function getTasksByUser(userId) {  // Renamed function to reflect that it's fetching tasks
        try {
            const res = await fetch(`/tasks/user/${userId}`);  // Updated the fetch URL
            const taskArray = await res.json();
            setTasks(taskArray);
        } catch (error) {
            console.error("Failed to fetch tasks:", error);
        }
    }
    
    useEffect(() => {
        getTasksByUser(userId);  // Updated the function name
    }, [userId]);

    // Filtering businesses based on the filters
    const filteredItems = tasks.filter(task => {

        // Check if current_step matches any selected option from the currentStepFilter
        const isSelectedTaskType =
        (!currentTypeFilter || currentTypeFilter.length === 0) ||
        currentTypeFilter.includes(task.type);

        const isSelectedTaskStatus =
        (!taskStatusFilter || taskStatusFilter.length === 0) ||
        taskStatusFilter.includes(task.status);


        return isSelectedTaskType && isSelectedTaskStatus;
    });

    const formatDate = (dateString) => {
        if (!dateString) {
          return 'NA';
        }
      
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };

      return (
        <div style={{ width: '25%', float: 'left' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ borderBottom: '1px solid black', backgroundColor: '#E1DFDF' }}>
                        <th style={{ padding: '8px', border: '1px solid black', backgroundColor: '#E1DFDF' }}>Task Name</th>
                        <th style={{ padding: '8px', border: '1px solid black', backgroundColor: '#E1DFDF' }}>Task Type</th>
                        <th style={{ padding: '8px', border: '1px solid black', backgroundColor: '#E1DFDF' }}>Status</th>
                        <th style={{ padding: '8px', border: '1px solid black', backgroundColor: '#E1DFDF' }}>Start Date</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems.map((task) => (
                        <tr key={task.id || task.task_name} style={{ backgroundColor: 'white' }}>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                <a href={`/task-profile/${task.id}`}>{task.task_name}</a>
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {task.type}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {task.status}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {formatDate(task ? task.start_date : null)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );    
};

export default ListComponent;
