import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./Login/LogoutButton";

const Navbar = () => {
  return (
    <nav style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F5F5F5", padding: "1.5rem", borderBottom: "3px solid #E1DFDF" }}>
<Link to="/" style={{ color: "#295069", textDecoration: "none", fontSize: "40px", fontFamily: 'sans-serif', fontWeight: "bold" }}>AcquireHub</Link>
      <ul style={{ display: "flex", justifyContent: "space-between", listStyle: "none", padding: "0" }}>
        <li style={{ marginLeft: '1rem' }}><a data-canny-link href="https://acquirehub.canny.io/acquirehub-feature-request" target="_blank" style={{ color: "#295069", textDecoration: "none", fontSize: "17px", fontFamily: 'Open Sans, sans-serif'  }}>Give Feedback</a></li>
        <li style={{ marginLeft: "1rem" }}>
          <Link to="/businesses-list" style={{ color: "#295069", textDecoration: "none", fontSize: "17px", fontFamily: 'Open Sans, sans-serif' }}>Businesses</Link>
        </li>
        <li style={{ marginLeft: "1rem" }}><Link to="/brokers-list" style={{ color: "#295069", textDecoration: "none", fontSize: "17px", fontFamily: 'Open Sans, sans-serif' }}>Brokers</Link></li>
        <li style={{ marginLeft: "1rem" }}><Link to="/user-profile" style={{ color: "#295069", textDecoration: " none", fontSize: "17px", fontFamily: 'Open Sans, sans-serif' }}>Profile</Link></li>
        <li style={{ marginLeft: "1rem" }}><LogoutButton /></li>
      </ul>
    </nav>
  );
};

export default Navbar;
