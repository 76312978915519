import React, { useState, useEffect } from 'react';
import TaxYearDataService from '../../services/TaxYearDataService';
import "../../styles/defaultloanstyles.css";
import OfferDataService from "../../services/OfferDataService";

const NewDefaultLoan = ({business, userId}) => {
    const [netProfit, setNetProfit ] = useState();
    const [SBAInterestRate, setSBAInterestRate] = useState();

    useEffect(() => {
        const businessId = business?.id;
        if (businessId) {
          TaxYearDataService.getTaxYearById(businessId, userId)
            .then((data) => {
                setNetProfit(data[0].average_net_profit);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }, [business?.id, userId]);


      useEffect(() => {
        const fetchSBAInterestRate = async () => {
          try {
            const rate = await OfferDataService.getSBAInterestRate(); // Fetch the SBA interest rate
            if (rate) {
              const SbaIR = parseFloat(rate) + 2.75;
              setSBAInterestRate(SbaIR)
            } else {
              // Handle the case where no rate is returned
              console.log('No SBA Interest Rate found.');
              // You might set a default value or handle this scenario as needed
            }
          } catch (error) {
            console.error('Failed to fetch SBA Interest Rate:', error);
            // Handle error, possibly setting default values or providing user feedback
          }
        };
      
        fetchSBAInterestRate();
      }, []);

      function sbaGuaranteeFee(loanAmount) {
        let guaranteeFee = 0;
      
        if (loanAmount <= 500000) {
          guaranteeFee = 0;
        } else if (loanAmount <= 700000) {
          guaranteeFee = loanAmount * 0.75 * 0.0055; // Corrected rate to 0.55%
        } else if (loanAmount <= 1000000) {
          guaranteeFee = loanAmount * 0.75 * 0.0375; // Corrected rate to 3.75%
        } else {
          guaranteeFee = ((loanAmount * 0.75) - 1000000) * 0.0375 + (1000000 * 0.035); // Calculating the fee for amounts over $1,000,000
        }
      
        return guaranteeFee;
      }

      const calcMonthlySBAPayment = (principal) => {
        const downpayment = principal*0.10;
        const sellersfinancing = principal*0.10;
        const loanTotal = principal - downpayment - sellersfinancing;
        const sbaFee = sbaGuaranteeFee(loanTotal);
        const principalwithfees = sbaFee + loanTotal;
        const interestRate = SBAInterestRate;
        const loanTerm = 120;
        const monthlyInterestRate = interestRate / 12 / 100;
        const z = Math.pow(1 + monthlyInterestRate, -loanTerm);
        const monthlyPayment = principalwithfees * monthlyInterestRate / (1 - z);
      
        return monthlyPayment;
    }
    
      const calcMonthlySellerPayment = (principal) => {
        const loanTotal = principal*0.10;
        const interestRate = 4.00;
        const loanTerm = 60;
        const monthlyInterestRate = interestRate / 12 / 100;
        const z = Math.pow(1 + monthlyInterestRate, -loanTerm);
        const monthlyPayment = loanTotal * monthlyInterestRate / (1 - z);
      
        return monthlyPayment;
    }

    const cashflowwithseller = (business) => {
        const sbaDebt = (calcMonthlySBAPayment(business.selling_price));
        const sellerDebt = (calcMonthlySellerPayment(business.selling_price));
        const cashflow = (netProfit/12) - sbaDebt - sellerDebt;
        return cashflow;
    
      }
    
      const cashflowwithoutseller = (business) => {
        const sbaDebt = (calcMonthlySBAPayment(business.selling_price));
        const cashflow = (netProfit/12) - sbaDebt;
        return cashflow;
      }
    
      const dscrwithseller = (business) => {
        const sbaDebtyearly = (calcMonthlySBAPayment(business.selling_price)*12);
        const sellerDebtYearly = (calcMonthlySellerPayment(business.selling_price)*12);
        const totaldebt = sbaDebtyearly + sellerDebtYearly;
        const dscr = netProfit/totaldebt;
        return dscr;
      }
    
      const dscrwithoutseller = (business) => {
        const sbaDebtyearly = (calcMonthlySBAPayment(business.selling_price)*12);
        const dscr = netProfit/sbaDebtyearly;
        return dscr;
      }


    return (
            <table className="table-bordered Content">
                <thead>
                    <tr>
                        <th className='no-border'>
                            <span className="Sub-Headers">Loan Details</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Purchase Price: {business.selling_price.toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                    </tr>
                    <tr>
                        <td>Down Payment: {(business.selling_price*.10).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <td>SBA Loan Terms: 10 years</td>
                    </tr>
                    <tr>
                        <td>SBA Interest Rate: {SBAInterestRate}%</td>
                    </tr>
                    <tr>
                        <td>SBA Monthly Payment: {calcMonthlySBAPayment(business.selling_price).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                    </tr>
                    <tr>
                        <td>SBA Yearly Payment: {(calcMonthlySBAPayment(business.selling_price)*12).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                    </tr>
                    <tr>
                        <td>Sellers Financing: {(business.selling_price*.10).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <td>Seller Loan Terms: 5 years</td>
                    </tr>
                    <tr>
                        <td>Seller Interest Rate: 4.00%</td>
                    </tr>
                    <tr>
                        <td>Seller Monthly Payment: {calcMonthlySellerPayment(business.selling_price).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                    </tr>
                    <tr>
                        <td>Seller Yearly Payment: {(calcMonthlySellerPayment(business.selling_price)*12).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</td>
                    </tr>
                    <tr>
                        <td>Cashflow (Seller note concurrent): {cashflowwithseller(business).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    </tr>
                    <tr>
                        <td>DSCR (Seller note concurrent): {dscrwithseller(business).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Cashflow (Seller note full standby): {cashflowwithoutseller(business).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                    </tr>
                    <tr>
                    <td>DSCR (Seller note full standby): {dscrwithoutseller(business).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
    );
}
export default NewDefaultLoan;