import axios from "axios";

const TaxYearDataService = {
  createTaxYear: (taxYears) => {
    return axios.post("/taxyear", taxYears);
  },

  getTaxYearById: (id, userId) => {
    return axios.get(`/taxyear/${id}`, {
      params: {
        userId: userId
      }
    })
    .then((response) => {
      // Handle successful response
      return response.data;
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  },  

  deleteTaxYear: (id, userId) => {
    return axios.delete(`/taxyear/${id}`, {
      params: {
        userId: userId
      }
    });
  },
  

  updateTaxYear: (updatedTaxYear) => {
    return axios.put('/taxyear', updatedTaxYear);
  },

  saveAverages: (averages, businessId, userId) => {
    // Include userId in the data sent to the server
    return axios.put('/taxyear/averages', { averages, businessId, userId });
  },   

};

export default TaxYearDataService;
