import React from 'react'
import '../../styles/chooseanalysis.css';
import { useNavigate, useParams } from "react-router-dom";

const ChooseAnalysis = (props) => {
    const { businessId } = useParams();
    const navigate = useNavigate();

    function startAnalysis() {
        const analysisSelect = document.querySelector('.choose-analysis-select');
        const selectedValue = analysisSelect.value;
      
        switch (selectedValue) {
          case 'SWOT':
            navigate(`/create-swot/${businessId}`)
            break;
          case 'PESTEL':
            navigate(`/choose-analysis/${businessId}`)
            break;
          default:
            // Handle the case where no analysis is chosen or an invalid option is selected
            alert('Please choose a valid analysis.');
            break;
        }
      }
      


  return (
    <div className="choose-analysis-container">
      <div className="choose-analysis-header">
        <h1 className="choose-analysis-choose-analysis-type Headers">
          Choose Analysis Type
        </h1>
        <div className="choose-analysis-swot-container">
          <span className="choose-analysis-swot-description Content">
            A SWOT analysis is a strategic planning tool used to identify and
            understand the Strengths, Weaknesses, Opportunities, and Threats
            related to a business.
          </span>
        </div>
        <div className="choose-analysis-pestel-container">
          <span className="choose-analysis-pestel-description Content">
            PESTEL stands for Political, Economic, Social, Technological,
            Environmental, and Legal factors. It is a framework used to analyze
            and monitor the macro-environmental factors that may have a profound
            impact on an organization&apos;s performance. This analysis is used
            to identify the external factors that could affect the business
            strategies, decisions, and future direction of the company.
          </span>
        </div>
        <div className="choose-analysis-porters-container">
          <span className="choose-analysis-porters-description Content">
            <span className="Content">Porter&apos;s </span>
            <span>
              framework is used to analyze the competitive environment and
              understand where the power lies in a business situation. The five
              forces model helps identify the attractiveness and potential
              profitability of an industry sector:
            </span>
          </span>
        </div>
      </div>
      <div className="choose-analysis-button-row">
        <div className="choose-analysis-button-column">
          <select className="choose-analysis-select">
            <option value="Choose">Choose an Analysis</option>
            <option value="SWOT">SWOT</option>
            <option value="PESTEL">PESTEL</option>
          </select>
          <button
            type="button"
            className="choose-analysis-start-button button Content"
            onClick={startAnalysis}
          >
            Start Analysis
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChooseAnalysis
