import axios from "axios";

const TaskDataService = {
  createTask: (task) => {
    return axios.post("/LOI/tasks", task)
      .then(response => {
        // Extract taskId from the response
        const taskId = response.data.taskId;
        // Return an object or the taskId directly, depending on how you plan to use this function
        return { success: true, taskId };
      })
      .catch(error => {
        // Handle error
        console.error('Error creating task:', error);
        // Depending on your error handling strategy, you might want to rethrow the error or return a specific error object
        throw error;
      });
  },  

      //passing business ID
      getTaskById: (id, userId) => {
        return axios.get(`/LOI/tasks/${id}`, {
          params: { userId } // Pass the userId as a parameter
        })
        .then((response) => {
          // Handle successful response
          return response.data;
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
      },      

      getDueDilTaskById: (id, userId) => {
        return axios.get(`/DUEDIL/tasks/${id}`, {
          params: { userId } // Pass the userId as a parameter
        })
        .then((response) => {
          // Handle successful response
          return response.data;
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
      },      

      updateTasks: (updatedTask, userId) => {
        return axios.put('/tasks', updatedTask, {
          params: { userId } // Pass the userId as a parameter
        });
      },      

      getTask: (id, userId) => {
        return axios.get(`/tasksID/${id}`, {
          params: { userId } // Pass the userId as a parameter
        })
        .then((response) => {
          // Handle successful response
          return response.data;
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
      },      

      deleteTask: (id, userId) => {
        return axios.delete(`/tasks/${id.toString()}`, {
          params: { userId } // Pass the userId as a parameter
        });
      },      

      updateTaskInfo: (id, updatedTask, userId) => {  
        return axios.put(`/Updatetasks/${id.toString()}`, updatedTask, {
          params: { userId } // Pass the userId as a parameter
        }); 
      },      

      createTaskDueDiligence: (task) => {
          return axios.post("/DueDil/tasks", task);
        },

        completeTasksForBusiness: (businessId, userId) => {
          return axios.put('/complete-tasks', {}, { // Pass an empty object for the body
            params: { business_id: businessId, user_id: userId } // Correctly configure query params here
          })
          .then(response => {
            // Handle successful response
            console.log('Tasks updated successfully:', response.data);
            return response.data;
          })
          .catch(error => {
            // Handle error
            console.error('Error updating tasks for business:', error);
            throw error;
          });
        },
        
  
};

export default TaskDataService;
