import axios from "axios";

const BusinessDataService = {
  getBusinesses: () => {
    return axios.get("/businesses");
  },

  deleteBusiness: (id, userId) => {
    return axios.delete(`/businesses/${id.toString()}`, { params: { userId } });
  },  

  updateBusiness: (id, updatedBusiness, userId) => {
  
    // Including userId in the request body
    const data = {
      ...updatedBusiness,
      userId
    };
  
    return axios.put(`/businesses/${id.toString()}`, data);
  },  

  updateInterestedStatus: (id, interested) => {
    return axios.put(`/businesses/interested/${id.toString()}`, { interested_in_business: interested });
  },  

  updateNDAStatus: (id, Submitted) => {
    return axios.put(`/businesses/nda/${id.toString()}`, { nda_submitted: Submitted });
  },

  updateLIOStatus: (id, Submitted) => {
    return axios.put(`/businesses/loi/${id.toString()}`, { loi_submitted: Submitted });
  },

  updatePreLOIStatus: (id, Submitted) => {
    return axios.put(`/businesses/PreLOI/${id.toString()}`, { preLOI_submitted: Submitted });
  },
 
  searchBusinesses: (searchTerm, userId) => {
    return axios.get(`/businesses/search/${searchTerm}`, {
      params: {
        userId: userId
      }
    });
  },

  updateDuaDilingenceCompleted: (id, Submitted) => {
    return axios.put(`/businesses/duaDiligence/${id.toString()}`, { dua_diligence_completed: Submitted });
  },

// createBusiness function
createBusiness: (Name, SellingPrice, broker_id, franchise, listingUrl, yearEstablished, numberOfEmployees, userId, city, state, sde, gross_revenue, business_type) => {
  return axios.post("/businesses", { 
    name: Name,
    selling_price: SellingPrice,
    broker_id: broker_id,
    franchise: franchise,
    listing_url: listingUrl,
    year_established: yearEstablished,
    number_of_employees: numberOfEmployees,
    user_id: userId,
    city: city,
    state: state,
    sde: sde,
    gross_revenue: gross_revenue,
    business_type: business_type // Include the business_type parameter
  });
},

getBusinessById: (id, userId) => {
  return axios.get(`/business/${id}`, { params: { userId } });
}

};

export default BusinessDataService;
