import React, { useState, useEffect } from 'react';
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import TaskDataService from '../../services/TaskDataService';
import { useNavigate } from "react-router-dom";
import '../../styles/CalendarStyles.css';

const CalendarPage = ({ business, userId }) => {
    const [calendarTasks, setCalendarTasks] = useState([]); // State for tasks displayed on the calendar
    const [sideComponentTasks, setSideComponentTasks] = useState([]); // State for tasks in the side component
    const navigate = useNavigate();

    useEffect(() => {
        const businessId = business?.id;
        if (businessId) {
            TaskDataService.getDueDilTaskById(businessId, userId)
                .then((data) => {
                    // Separate tasks into calendarTasks and sideComponentTasks
                    const tasksWithDates = data.filter(task => task.start_date || task.due_date);
                    const tasksWithoutDates = data.filter(task => !task.start_date && !task.due_date);

                    setCalendarTasks(tasksWithDates);
                    setSideComponentTasks(tasksWithoutDates);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [business?.id]);

    const initializeDraggable = () => {
        const element = document.getElementById('side-container');
        if (element) {
            new Draggable(element, {
                itemSelector: '.draggable-event',
                eventData: eventElement => {
                    const eventId = eventElement.getAttribute('data-id');
                    return { id: eventId, title: eventElement.innerText.trim() };
                },
            });
        }
    };

    useEffect(() => {
        initializeDraggable();
    }, [sideComponentTasks]);

    const handleDrop = (info) => {
        const taskId = info.draggedEl.getAttribute('data-id');
        const selectedTask = sideComponentTasks.find(task => task.id.toString() === taskId);
        
        if (selectedTask) {
            const updatedTaskData = {
                task_name: selectedTask.task_name,
                status: selectedTask.status,
                due_date: selectedTask.due_date,
                start_date: info.date, // Start date from the drop event
            };
    
            // Update the start_date of selectedTask directly
            selectedTask.start_date = info.date;
    
            TaskDataService.updateTaskInfo(taskId, updatedTaskData, userId)
                .then(() => {
                    console.log('Task start date updated successfully');
    
                    const updatedSideTasks = sideComponentTasks.filter(task => task.id !== selectedTask.id);
                    setSideComponentTasks(updatedSideTasks);
                    setCalendarTasks(prevCalendarTasks => [...prevCalendarTasks, selectedTask]);
                })
                .catch(error => {
                    console.error('Error updating task start date:', error);
                });
        }
    };    

    const handleEventClick = (info) => {
        const taskId = info.event.id;
        navigate(`/task-profile/${taskId}`);
    };

    const handleEventChange = (info) => {
        // Extract the new start date from the changed event
        const newStartDate = info.event.start;
        
        // Find the corresponding task in the calendarTasks array based on the event ID
        const taskId = info.event.id;
        const updatedTasks = calendarTasks.map(task => {
            if (task.id.toString() === taskId) {
                // Update the start date of the task
                task.start_date = newStartDate;
            }
            return task;
        });
        
        // Update the state with the modified tasks
        setCalendarTasks(updatedTasks);
        
        // Find the corresponding task data based on the event ID
        const updatedTask = updatedTasks.find(task => task.id.toString() === taskId);
        
        // Prepare the updated task data (excluding task ID)
        const updatedTaskData = {
            task_name: updatedTask.task_name,
            status: updatedTask.status,
            due_date: updatedTask.due_date,
            start_date: newStartDate, // Updated start date
        };
        
        // Make an API call to update the task in the database
        TaskDataService.updateTasks(updatedTaskData, userId)
            .then(() => {
                console.log('Task start date updated successfully');
            })
            .catch((error) => {
                console.error('Error updating task start date:', error);
            });
    };
    

    return (
        <div className="flex-container">
          <div className="flex-column-center">
            <h1>Tasks</h1>
            <div className="flex-align-center">
              <button type="button" onClick={() => { navigate(`/add-task/${business.id}`); }}>Add Task</button>
            </div>
            <div id="side-container" className="side-container">
              {sideComponentTasks?.length > 0 ? (
                sideComponentTasks.map(task => (
                  <span
                    key={task.id}
                    className="draggable-event"
                    data-id={String(task.id)} // use data attribute instead of id
                  >
                    <h3><a href={`/task-profile/${task.id}`}>{task.task_name}</a></h3>
                  </span>
                ))
              ) : (
                <p>No tasks available.</p>
              )}
            </div>
          </div>
          <div className="full-calendar-container">
          <FullCalendar
                    plugins={[daygridPlugin, interactionPlugin]}
                    droppable={true}  
                    drop={handleDrop}
                    events={calendarTasks.map(task => ({
                        id: task.id.toString(),
                        title: task.task_name,
                        start: task.start_date,
                        end: task.due_date,
                        editable: true,
                    }))}
                    eventClick={handleEventClick}
                    eventChange={handleEventChange} // Handle event changes (e.g., moving tasks)
                    headerToolbar={{
                        left: '', // Remove the month and year from the top left corner
                        center: 'title', // Center the month and year
                    }}
                />
          </div>
        </div>
      );      
}

export default CalendarPage;

