import React, { useState, useEffect } from 'react';
import LoginDataService from '../../services/LoginDataService';
import '../../styles/UserProfile.css';
import '../../styles/EditUserProfile.css';
import UserLimitDataService from '../../services/UserLimitDataService';

const UserProfile = ({ user }) => {
    const [editProfile, setEditableProfile] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [editableUser, setEditableUser] = useState(null);
    const [updatedUser, setUpdatedUser] = useState({});


    useEffect(() => {
      const userId = user.sub;
      LoginDataService.getUser(userId)
        .then(response => {
          setUserInfo(response.data);
        })
        .catch(err => console.error(err));
    }, [user.sub]);

    const handleEdit = () => {
        setEditableUser(userInfo);
        setUpdatedUser(userInfo);
        setEditableProfile(true);
      };
    
      const userId = user.sub;
      const handleSave = () => {
        
        LoginDataService.updateUser(userId, updatedUser)
          .then((response) => {
            setUserInfo(response.data); // Update the user information with the response data
            setEditableProfile(false); // Exit edit mode
            window.location.reload();
          })
          .catch((error) => {
            console.error(error); // Handle the error as needed
          });
      };

      const handleSendEmails = () => {
        UserLimitDataService.triggerEmailUpdate()
          .then(response => {
            // Handle the success response
            console.log(response.message);
            alert('Email update process initiated successfully.' + response.message );
          })
          .catch(error => {
            // Handle any errors
            console.error('Error triggering email update:', error);
            alert('There was an error initiating the email update process.');
          });
      };

      const handleExport = (userId) => {
        LoginDataService.getUserData(userId)
          .then(data => {
            // Assuming data contains { brokers, businesses, offers, tasks, taxYear }
      
            // Function to convert an array of objects to CSV
            const toCSV = (data, tableName) => {
              if (!data || !data.length) return '';
      
              const headers = Object.keys(data[0]).join(',');
              const rows = data.map(row => Object.values(row).join(',')).join('\n');
      
              return `\n${tableName}\n${headers}\n${rows}\n`;
            };
      
            // Convert each table's data to CSV format
            const brokersCSV = toCSV(data.brokers, 'Brokers');
            const businessesCSV = toCSV(data.businesses, 'Businesses');
            const offersCSV = toCSV(data.offers, 'Offers');
            const tasksCSV = toCSV(data.tasks, 'Tasks');
            const taxYearCSV = toCSV(data.taxYear, 'Tax Year');
      
            // Combine all CSV content
            const combinedCSV = [brokersCSV, businessesCSV, offersCSV, tasksCSV, taxYearCSV].join('\n');
      
            // Trigger download
            const blob = new Blob([combinedCSV], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
      
            // Construct filename with userId and current timestamp
            const timestamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0]; // YYYYMMDDHHMMSS format
            const fileName = `${userInfo.name}_AcquireHub_Data_${timestamp}.csv`;
            link.setAttribute('download', fileName);
      
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('Error handling export:', error);
          });
      };      
      
      

  return (
    <div>
  {editProfile ? (
    <div className="user-profile-edit-container">
    <div className="user-profile-edit-user-information">
      <div className="user-profile-edit-user-fields">
        <input
            type="text"
            placeholder="Name"
            className="user-profile-edit-name Content"
            value={updatedUser.name || ""}
            onChange={(e) => setUpdatedUser({ ...updatedUser, name: e.target.value })}
          />
        <input
          type="text"
          placeholder="Email"
          className="user-profile-edit-email input Content"
          value={updatedUser.email || ""}
          onChange={(e) => setUpdatedUser({ ...updatedUser, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="Phone:"
          className="user-profile-edit-phone input Content"
          value={updatedUser.phone || ""}
          onChange={(e) => setUpdatedUser({ ...updatedUser, phone: e.target.value })}
        />
        <input
          type="text"
          placeholder="Address:"
          className="user-profile-edit-address input Content"
          value={updatedUser.address || ""}
          onChange={(e) => setUpdatedUser({ ...updatedUser, address: e.target.value })}
        />
      </div>
      <svg viewBox="0 0 1024 1024" className="user-profile-edit-save-button" onClick={handleSave}>
        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
      </svg>
      <svg
        viewBox="0 0 1024 1024"
        className="user-profile-edit-cancel-button"
        onClick={() => setEditableProfile(null)}
      >
        <path d="M512 854q140 0 241-101t101-241q0-114-74-210l-478 478q96 74 210 74zM170 512q0 114 74 210l478-478q-96-74-210-74-140 0-241 101t-101 241zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
      </svg>
    </div>
    <div className="user-profile-edit-target-statement-container">
      <h1 className="user-profile-edit-target-statement">
        I am Looking for a [business type] company with a [growth
        opportunity], generating between [min] and [max] SDE!
      </h1>
    </div>
    <div className="user-profile-edit-target-business">
      <div className="user-profile-edit-target-header-container">
        <h1 className="user-profile-edit-title Headers">
          What is your Target Business?
        </h1>
        <div className="user-profile-edit-business-type">
          <h1 className="user-profile-edit-type-label Headers">
            Business Type: 
          </h1>
          <select className="user-profile-edit-type-value" defaultValue={updatedUser.business_type || "product"}
              onChange={(e) => setUpdatedUser({ ...updatedUser, business_type: e.target.value })}>
              <option value="Product">Product</option>
              <option value="Distribution">Distribution</option>
              <option value="Service">Service</option>
          </select>
        </div>
        <div className="user-profile-edit-growth-opp-container">
          <div className="user-profile-edit-growth-opp">
            <h1 className="user-profile-edit-growth-title Headers">
              Growth Opportunity
            </h1>
            <div className="user-profile-edit-pro-container">
              <span className="user-profile-edit-pro-label Content">
                What the business does well:  
              </span>
              <textarea
                className="user-profile-edit-pro-value textarea Content"
                value={updatedUser.business_positive || ""}
                onChange={(e) =>
                setUpdatedUser({ ...updatedUser, business_positive: e.target.value })
                  }
              ></textarea>
            </div>
            <div className="user-profile-edit-con-container">
              <span className="user-profile-edit-con-label Content">
                What skills you provide for growth: 
              </span>
              <textarea
                className="user-profile-edit-con-value textarea Content"
                value={updatedUser.business_negative || ""}
                onChange={(e) => setUpdatedUser({ ...updatedUser, business_negative: e.target.value })}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="user-profile-edit-sde-range">
        <h1 className="user-profile-edit-sde-title Headers">SDE Range</h1>
        <div className="user-profile-edit-min-container">
          <span className="user-profile-edit-min-label Content">
            Minimum: 
          </span>
          <input
            type="text"
            className="user-profile-edit-min-value input Content"
            value={updatedUser.sde_min || ""}
            onChange={(e) => setUpdatedUser({ ...updatedUser, sde_min: e.target.value })}
          />
        </div>
        <div className="user-profile-edit-max-container">
          <span className="user-profile-edit-max-label Content">
            Maximum: 
          </span>
          <input
            type="text"
            className="user-profile-edit-max-value input Content"
            value={updatedUser.sde_max || ""}
            onChange={(e) => setUpdatedUser({ ...updatedUser, sde_max: e.target.value })}
          />
        </div>
      </div>
    </div>
  </div>

        ) : (
          <div className="user-profile-container">
          <div className="user-profile-user-information">
            <div className="user-profile-user-info">
              <h1 className="user-profile-name Headers">{userInfo ? userInfo.name: ''}</h1>
              <span className="user-profile-email Content">Email: {userInfo ? userInfo.email: ''}</span>
              <span className="user-profile-number Content">Phone: {userInfo ? userInfo.phone: ''}</span>
              <span className="user-profile-address Content">Address: {userInfo ? userInfo.address: ''}</span>
            </div>
            <div className="user-profile-button-container">
            <svg viewBox="0 0 1024 1024" className="user-profile-edit-button" onClick={handleEdit}>
              <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
            </svg>
            <svg viewBox="0 0 1024 1024" className="user-profile-export-button" onClick={() => handleExport(userId)}>
              <path d="M214 768h596v86h-596v-86zM810 384l-298 298-298-298h170v-256h256v256h170z"></path>
            </svg>
            </div>
          </div>
          <div className="user-profile-target-statement-container">
            <h1 className="user-profile-target-statement">
              I am looking for a {userInfo?.business_type} company with {userInfo?.business_positive} but 
              {userInfo?.business_negative}, generating between {" "}${Number(userInfo?.sde_min)?.toLocaleString()}{" "}
               and ${Number(userInfo?.sde_max)?.toLocaleString()} SDE!
            </h1>
          </div>
        </div>
        )}
    </div>
  )
}

export default UserProfile
