import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditBroker = ({ brokers, updateBroker }) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [broker, setBroker] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    const selectedBroker = brokers.find((broker) => broker.id === parseInt(id));
    setBroker(selectedBroker);
  }, [brokers, id]);

  const { firstName, lastName, phoneNumber, email } = broker;

  const onInputChange = (e) => {
    setBroker({ ...broker, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateBroker(broker);
    navigate("/brokers-list"); // Navigate to Broker list page
  };

  return (
    <div>
      <h1>Edit Broker</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          name="firstName"
          value={firstName}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          name="lastName"
          value={lastName}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="text"
          name="phoneNumber"
          value={phoneNumber}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          value={email}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <button type="submit">Update Broker</button>
      </form>
    </div>
  );
};

export default EditBroker;
