import BusinessDataService from "../../services/BusinessDataService";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../styles/SubmitNDA.css';
import '../../styles/EditSubmitNDA.css'
import UserLimitDataService from "../../services/UserLimitDataService";
import TaskDataService from "../../services/TaskDataService";

const SubmitNda = ({ user, business, id,  setBusiness}) => {

    const navigate = useNavigate();
    const [editableBusiness, setEditableBusiness] = useState(null);
    const [updatedBusiness, setUpdatedBusiness] = useState({});
    const userId = user.sub;


    const US_STATES = [
      'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
      'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
      'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
      'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
      'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
      'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
      'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
      'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
      'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    const handleDelete = async (id) => {
      try {
        // Decrement the business count first
        await UserLimitDataService.decrementBusinessCount(userId);
    
        // After decrementing the business count, you can delete the business
        await BusinessDataService.deleteBusiness(id, userId);
    
        // Navigate to '/businesses-list'
        navigate('/businesses-list');
      } catch (error) {
        console.error(error);
        alert("An error occurred while deleting the business."); // You can display a user-friendly error message here.
      }
    };

    const notInterested = async (id) => { 
      try {
        await BusinessDataService.updateInterestedStatus(id, false);
        // Call completeTasksForBusiness after updating the interested status
        await TaskDataService.completeTasksForBusiness(business.id, userId); 
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }; 

    const ndaSubmitted = async (id) => {
      try{
      await BusinessDataService.updateNDAStatus(id, true);
      window.location.reload();
      } catch (error){
        console.error(error);
      }
    };  

      const calcProfitMargin = (business) => {
        const SDE = Number(business.sde);
        const Gross = Number(business.gross_revenue);
        const ProfitMargin = (SDE / Gross)*100;
        return ProfitMargin;
      };

      const calcMultiple = (business) => {
        const Price = Number(business.selling_price);
        const SDE = Number(business.sde);
        const multiple = Price / SDE;
        return multiple;
      };

      const handleEdit = (business) => {
        if (business) {
          setEditableBusiness(business);
          setUpdatedBusiness({ ...business });
        }
      };

      const handleCancel = () => {
        setEditableBusiness(null);
      };


      const handleSave = async (id) => {
        const processedBusiness = { ...updatedBusiness };
    
        if (typeof processedBusiness.selling_price === "string") {
          processedBusiness.selling_price = processedBusiness.selling_price
            .replace("$", "")
            .replace(/,/g, "");
        }
    
        Object.keys(processedBusiness).forEach((key) => {
          if (processedBusiness[key] === "") {
            processedBusiness[key] = null;
          }
        });
    
        try {
          const response = await BusinessDataService.updateBusiness(
            id,
            processedBusiness,
            userId
          );
    
          if (response.status === 200) {
            setBusiness({ ...business, ...updatedBusiness });
            window.location.reload();
            //alert("Business updated successfully");
          } else {
            alert("Failed to update business");
          }
          setEditableBusiness(null);
        } catch (error) {
          console.error(error);
          alert("Failed to update business");
        }
      };

      const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        let inputValue = type === "checkbox" ? checked : value;
      
        if (name === "selling_price" && typeof inputValue === "string") {
          inputValue = inputValue.replace("$", "");
        }
      
        if (inputValue === null) {
          inputValue = ""; // Convert null values to empty strings
        }
      
        setUpdatedBusiness({ ...updatedBusiness, [name]: inputValue });
      };

      const truncateText = (text, maxLength) => {
        if (!text) return '';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      };
      
      // Your component or rendering logic here
      


      return (
        <div>
          {editableBusiness && editableBusiness.id === business.id ? (
            <div className="submit-nda-edit-container">
            <div className="submit-nda-edit-nda-header">
              <div className="submit-nda-edit-main-information">
                <input
                  type="text"
                  placeholder="Business Name"
                  className="submit-nda-edit-business-name input Content"
                  name="name"
                  value={updatedBusiness.name}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="Selling Price"
                  className="submit-nda-edit-selling-price input Content"
                  id="sellingPrice"
                  name="selling_price"
                  value={(updatedBusiness.selling_price || 0).toLocaleString()}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="Gross Revenue"
                  className="submit-nda-edit-gross-revenue input Content"
                  id="grossRevenue"
                  name="gross_revenue"
                  value={updatedBusiness.gross_revenue}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="SDE"
                  className="submit-nda-edit-sde input Content"
                  id="sde"
                  name="sde"
                  value={updatedBusiness.sde}
                  onChange={handleInputChange}
                />
              </div>
              <div className="submit-nda-edit-buttons">
                <svg viewBox="0 0 1024 1024" className="submit-nda-edit-save" onClick={() => handleSave(business.id)}>
                  <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="submit-nda-edit-cancel" onClick={handleCancel}>
                  <path d="M512 854q140 0 241-101t101-241q0-114-74-210l-478 478q96 74 210 74zM170 512q0 114 74 210l478-478q-96-74-210-74-140 0-241 101t-101 241zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                </svg>
              </div>
            </div>
            <div className="submit-nda-edit-main">
              <div className="submit-nda-edit-general-information">
                <div className="submit-nda-edit-header">
                  <h1 className="submit-nda-edit-title Headers">
                    General Information
                  </h1>
                </div>
                <div className="submit-nda-edit-business-type-row">
                <select className="submit-nda-edit-business-type-value" id="business_type" name="business_type" value={updatedBusiness.business_type} onChange={handleInputChange}>
                  <option value="none">Select Type</option>
                    <option value="Distribution">Distribution</option>
                    <option value="Product">Product</option>
                    <option value="Service">Service</option>
                  </select>
                </div>
                <div className="submit-nda-edit-growth-row">
                  <div className="submit-nda-edit-pro-col">
                    <textarea
                      placeholder="What the Business does well:"
                      className="submit-nda-edit-pro-value textarea Content"
                      id="business_positive" 
                      name="business_positive" 
                      value={updatedBusiness.business_positive } 
                      onChange={handleInputChange }
                    ></textarea>
                  </div>
                  <div className="submit-nda-edit-con-column">
                    <textarea
                      placeholder="How to grow the Business:"
                      className="submit-nda-edit-con-value textarea"
                      id="business_negative" 
                      name="business_negative" 
                      value={updatedBusiness.business_negative} 
                      onChange={handleInputChange }
                    ></textarea>
                  </div>
                </div>
                <div className="submit-nda-edit-listing-url">
                  <input
                    type="text"
                    placeholder="Listing URL"
                    className="submit-nda-edit-url-value input Content"
                    id="listingUrl"
                    name="listing_url"
                    value={updatedBusiness.listing_url}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="submit-nda-edit-other-fields">
                  <div className="submit-nda-edit-left-other-fields">
                    <div className="submit-nda-edit-franchise">
                      <span className="submit-nda-edit-franchise-value Content">
                        Franchise: 
                      </span>
                      <input
                        type="checkbox"
                        checked={updatedBusiness.franchise}
                        className="submit-nda-edit-franchise-checkbox"
                        id="franchise"
                        name="franchise"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="submit-nda-edit-city">
                      <input
                        type="text"
                        placeholder="City"
                        className="submit-nda-edit-city-value input Content"
                        id="city"
                        name="city"
                        value={updatedBusiness.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="submit-nda-edit-number-of-employees">
                      <input
                        type="text"
                        placeholder="Number of Employees"
                        className="submit-nda-edit-number-of-employees-value input Content"
                        id="numberOfEmployees"
                        name="number_of_employees"
                        value={updatedBusiness.number_of_employees}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="submit-nda-edit-right-other-fields">
                    <div className="submit-nda-edit-year">
                      <input
                        type="text"
                        placeholder="Year Established"
                        className="submit-nda-edit-year-value input Content"
                        id="yearEstablished"
                        name="year_established"
                        value={updatedBusiness.year_established}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="submit-nda-edit-state">
                    <label>State</label>
                      <select
                      className="submit-nda-edit-state-value input"
                      value={updatedBusiness.state}
                      onChange={(e) =>
                          setUpdatedBusiness({
                          ...updatedBusiness,
                          state: e.target.value,
                          })
                      }
                      >
                      <option value="">-- Select a state --</option>
                      {US_STATES.map((state) => (
                          <option key={state} value={state}>
                          {state}
                          </option>
                      ))}
                      </select>
                    </div>
                    <div className="submit-nda-edit-broker-name">
                      <span className="submit-nda-edit-broker-name-value Content">
                        Broker Name: {business.broker.broker_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-nda-edit-general-financials">
                <div className="submit-nda-edit-fin-header">
                  <h1 className="submit-nda-edit-fin-title Headers">
                    General Financials
                  </h1>
                </div>
                <div className="submit-nda-edit-fin-fields">
                  <div className="submit-nda-edit-left-fin-fields">
                    <div className="submit-nda-edit-inventory">
                      <input
                        type="text"
                        placeholder="Inventory Value"
                        className="submit-nda-edit-inventory-value input Content"
                        id="inventoryValue"
                        name="inventory_value"
                        value={updatedBusiness.inventory_value}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="submit-nda-edit-real-estate">
                      <input
                        type="text"
                        placeholder="Real Estate Value"
                        className="submit-nda-edit-re-value input Content"
                        id="realEstateValue"
                        name="real_estate_value"
                        value={updatedBusiness.real_estate_value}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="submit-nda-edit-right-fin-fields">
                    <div className="submit-nda-edit-ffe">
                      <input
                        type="text"
                        placeholder="FF&amp;E Value"
                        className="submit-nda-edit-ffe-value input Content"
                        id="ffe_value"
                        name="ffe_value"
                        value={updatedBusiness.ffe_value}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="submit-nda-edit-re-included">
                      <span className="submit-nda-edit-re-label Content">
                        Real Estate Included? 
                      </span>
                      <input
                        type="checkbox"
                        checked={updatedBusiness.real_estate_included}
                        className="submit-nda-edit-re-checkbox"
                        id="realEstateIncluded"
                        name="real_estate_included"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) : (
            <div className="submit-nda-container">
      <div className="submit-nda-nda-header">
        <div className="submit-nda-main-information">
          <h1 className="submit-nda-business-name Headers">{business.name}</h1>
          <span className="submit-nda-selling-price Content">
            Selling Price: {business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}
          </span>
          <span className="submit-nda-gross-revenue Content">
            Gross Revenue: {business.gross_revenue ? `$${business.gross_revenue.toLocaleString()}` : 'N/A'}
          </span>
          <span className="submit-nda-sde Content">SDE: {business.sde ? `$${business.sde.toLocaleString()}` : 'N/A'}</span>
        </div>
        <div className="submit-nda-buttons">
          <button
            type="button"
            className="submit-nda-submit-nda-button button Content"
            onClick={() => ndaSubmitted(business.id)}
          >
            Submitted NDA
          </button>
          <button
            type="button"
            className="submit-nda-not-interested-button button Content"
            onClick={() => notInterested(business.id)}
          >
            Not Interested
          </button>
          <svg viewBox="0 0 1024 1024" className="submit-nda-edit-button" onClick={() => handleEdit(business)}>
            <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
          </svg>
          <svg viewBox="0 0 1024 1024" className="submit-nda-delete-button" onClick={() => handleDelete(business.id)}>
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      </div>
      <div className="submit-nda-main">
      <div className="submit-nda-right-container">
        <div className="submit-nda-general-information">
          <div className="submit-nda-header">
            <h1 className="submit-nda-title Headers">General Information</h1>
          </div>
          <div className="submit-nda-business-type-row">
            <span className="submit-nda-businesstypevalue Content">
              Business Type: {business.business_type}
            </span>
          </div>
          <div className="submit-nda-growth-row">
            <div className="submit-nda-pro-col">
            <div className="submit-nda-pro-value Content">
              What the business does well:<br />
              {business.business_positive}
            </div>
            </div>
            <div className="submit-nda-con-column">
              <div className="submit-nda-con-text Content">
                How to grow the Business:<br/> 
                {business.business_negative }
              </div>
            </div>
          </div>
          <div className="submit-nda-container1">
            <span className="submit-nda-listing-url Content">
              Listing URL: {" "}
                  <a className="truncate-link" href={business.listing_url} target="_blank" rel="noopener noreferrer">
                    {truncateText(business.listing_url, 40)}
                  </a>
            </span>
          </div>
          <div className="submit-nda-other-fields">
            <div className="submit-nda-left-other-fields">
              <div className="submit-nda-franchise">
                <span className="submit-nda-franchise-value Content">
                  Franchise: {business.franchise ? "Yes" : "No"}
                </span>
              </div>
              <div className="submit-nda-city">
                <span className="submit-nda-city-value Content">City: {business.city}</span>
              </div>
              <div className="submit-nda-number-of-employees">
                <span className="submit-nda-number-of-employees-value Content">
                  Number of Employees: {business.number_of_employees}
                </span>
              </div>
            </div>
            <div className="submit-nda-right-other-fields">
              <div className="submit-nda-year">
                <span className="submit-nda-year-value Content">
                  Year Established: {business.year_established}
                </span>
              </div>
              <div className="submit-nda-state">
                <span className="submit-nda-state-value Content">State: {business.state}</span>
              </div>
              <div className="submit-nda-broker-name">
                <span className="submit-nda-broker-name-value Content">
                  Broker Name: <a className="Content" href={`/broker/${business.broker.id}`}>{business.broker.broker_name}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="submit-nda-questions">
            <div className="submit-nda-header-container">
              <div className="submit-nda-header-container">
                <h1 className="submit-nda-questions-header Headers">Business Analysis</h1>
                <svg
                  viewBox="0 0 804.5714285714286 1024"
                  className="submit-nda-add-question"
                  onClick={() => {navigate(`/choose-analysis/${business.id}`);}}
                >
                  <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
              </div>
              </div>
            <div className="question-table Content">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Analysis Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {business && business.analyses && business.analyses.length > 0 ? (
                  business.analyses.slice(0, 5).map((analysis, index) => (
                    <tr key={analysis.analysis_id}>
                      <td className="table-text-wrap">{index + 1}</td>
                      <td className="table-text-wrap">
                        <a href={`/swot-profile/${analysis.analysis_id}`}>{analysis.analysis_type}</a>
                      </td>
                      <td className="table-text-wrap">{analysis.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No analyses added yet.</td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
          </div>
          </div>
        <div className="submit-nda-right-container">
        <div className="submit-nda-general-financials">
          <div className="submit-nda-header1">
            <h1 className="submit-nda-title1 Headers">General Financials</h1>
          </div>
          <div className="submit-nda-financial-fields">
            <div className="submit-nda-fin-left-fields">
              <div className="submit-nda-inventory">
                <span className="submit-nda-inventory-value Content">
                  Inventory Value: {business.inventory_value ? `$${business.inventory_value.toLocaleString()}` : 'N/A'}
                </span>
              </div>
              <div className="submit-nda-real-estate">
                <span className="submit-nda-real-estate-value Content">
                  Real Estate Value: {business.real_estate_value ? `$${business.real_estate_value.toLocaleString()}` : 'N/A'}
                </span>
              </div>
              <div className="submit-nda-multiple">
                <span className="submit-nda-multiple-value Content">
                  Multiple: {calcMultiple(business).toFixed(1)}
                </span>
              </div>
            </div>
            <div className="submit-nda-fin-right-fields">
              <div className="submit-nda-ffe">
                <span className="submit-nda-ffe-value Content">
                  FF&amp;E Value: {business.ffe_value ? `$${business.ffe_value.toLocaleString()}` : 'N/A'}
                </span>
              </div>
              <div className="submit-nda-re-included">
                <span className="submit-nda-re-included-value Content">
                  Real Estate Included? {business.real_estate_included ? "Yes" : "No"}
                </span>
              </div>
              <div className="submit-nda-profit-margin">
                <span className="submit-nda-profit-margin-value Content">
                  Profit Margin: {calcProfitMargin(business).toFixed(0)}%
                </span>
              </div>
            </div>
          </div>
        </div>
          <div className="submit-nda-questions">
            <div className="submit-nda-header-container">
              <div className="submit-nda-header-container">
                <h1 className="submit-nda-questions-header Headers">Questions</h1>
                <svg
                  viewBox="0 0 804.5714285714286 1024"
                  className="submit-nda-add-question"
                  onClick={() => {navigate(`/add-question/${business.id}`);}}
                >
                  <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                </svg>
              </div>
              </div>
            <div className="question-table Content">
            <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {business && business.questions && business.questions.length > 0 ? (
                    business.questions.slice(0, 10).map((question, index) => (
                      <tr key={question.id}>
                        <td className="table-text-wrap">{index + 1}</td>
                        <td className="table-text-wrap">
                          <a href={`/question-profile/${question.id}`}>{truncateText(question.question_text, 50)}</a>
                        </td>
                        <td className="table-text-wrap">{truncateText(question.answer_text, 50)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No questions added yet.</td>
                    </tr>
                  )}
                  {business && business.questions && business.questions.length > 10 && (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>
                      <a href={`/question-list/${business.id}`}>View All Questions</a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
  </div>
  ); 
}

export default SubmitNda
