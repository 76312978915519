import React, { useState, useEffect } from 'react';

const ListComponent = ({ user, filters, selectedPicklistOptions, currentStepFilter }) => {
    const [businesses, setBusinesses] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const userId = user.sub;
    const [sortDirection, setSortDirection] = useState('asc'); // or 'desc' for descending
    const [sortColumn, setSortColumn] = useState(null);

    async function getBusinesses(userId) {
        try {
            const res = await fetch(`/businesses/${userId}`);
            const busArray = await res.json();
            setBusinesses(busArray);
        } catch (error) {
            console.error("Failed to fetch businesses:", error);
        }
    }

    useEffect(() => {
        getBusinesses(userId);
    }, [userId]);

    useEffect(() => {
        // Filtering businesses based on the filters
        const filtered = businesses.filter(business => {
            const isWithinYearRange = (
                (!filters.startYear || business.year_established >= filters.startYear) &&
                (!filters.endYear || business.year_established <= filters.endYear)
            );

            const isWithinPriceRange = (
                (!filters.startPrice || business.selling_price >= filters.startPrice) &&
                (!filters.endPrice || business.selling_price <= filters.endPrice)
            );

            const isWithinSDERange = (
                (!filters.startSDE || business.sde >= filters.startSDE) &&
                (!filters.endSDE || business.sde <= filters.endSDE)
            );

            // Check if business_type matches any selected option from the picklist
            const isSelectedBusinessType =
                (!selectedPicklistOptions || selectedPicklistOptions.length === 0) ||
                (selectedPicklistOptions.length === 1 && selectedPicklistOptions[0] === "None") ||
                selectedPicklistOptions.includes(business.business_type);

            // Check if current_step matches any selected option from the currentStepFilter
            const isSelectedCurrentStep =
                (!currentStepFilter || currentStepFilter.length === 0) ||
                currentStepFilter.includes(business.current_step);

            return isWithinYearRange && isWithinPriceRange && isWithinSDERange && isSelectedBusinessType && isSelectedCurrentStep;
        });

        // Sorting the filtered items
        const sorted = [...filtered];
        if (sortColumn === 'selling_price') {
            sorted.sort((a, b) => {
                if (sortDirection === 'asc') {
                    return a.selling_price - b.selling_price;
                } else {
                    return b.selling_price - a.selling_price;
                }
            });
        } else if (sortColumn === 'sde') {
            sorted.sort((a, b) => {
                if (sortDirection === 'asc') {
                    return a.sde - b.sde;
                } else {
                    return b.sde - a.sde;
                }
            });
        }

        setFilteredItems(sorted);
    }, [businesses, filters, selectedPicklistOptions, currentStepFilter, sortColumn, sortDirection]);

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    return (
        <div style={{ width: '86%', float: 'left' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
                    <tr style={{ borderBottom: '1px solid black', backgroundColor: '#E1DFDF' }}>
                        <th style={{ padding: '8px', border: '1px solid black', color: '#333', fontWeight: 'bold' }}>Business Name</th>
                        <th style={{ padding: '8px', border: '1px solid black', color: '#333', fontWeight: 'bold' }}>Business Type</th>
                        <th style={{ padding: '8px', border: '1px solid black', color: '#333', fontWeight: 'bold' }}>Current Step</th>
                        <th style={{ padding: '8px', border: '1px solid black', cursor: 'pointer', color: '#333', fontWeight: 'bold' }} onClick={() => handleSort('selling_price')}>
                            Selling Price {sortColumn === 'selling_price' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                        </th>
                        <th style={{ padding: '8px', border: '1px solid black', cursor: 'pointer', color: '#333', fontWeight: 'bold' }} onClick={() => handleSort('sde')}>
                            SDE {sortColumn === 'sde' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems.map((business, index) => (
                        <tr key={business.id || business.name} style={{ backgroundColor: 'white' }}>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                <a href={`/businessProfile/${business.id}`}>{business.name}</a>
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {business.business_type}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {business.current_step}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {Number(business.selling_price).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                })}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid black', backgroundColor: 'white' }}>
                                {Number(business.sde).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                })}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListComponent;
