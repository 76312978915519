import axios from "axios";

const QuestionDataService = {

    createQuestion: async (payload) => {
        try {
          const response = await axios.post("/questions", payload);
          return response.data.id; // Correct, expecting 'id' from the server response
        } catch (error) {
          console.error("There was an error creating the question:", error);
          throw error;
        }
      },
      getQuestionById: (id, userId) => {
        return axios.get(`/question/profile/${id}`, {
          params: { userId }
        });
      }, 
      deleteQuestion: (id, userId) => {
        return axios.delete(`/questions/${id.toString()}`, {
          params: { userId } 
        });
      },   
      saveQuestion: async (question, userId) => {
        if (!question.id) throw new Error("Question ID is required");
      
        try {
          const response = await axios.patch(`/questions/${question.id}`, {
            ...question,
            userId // Include the userId if needed for the server to process the save request
          });
          return response.data; // You might want to return the whole response or just the data
        } catch (error) {
          console.error("There was an error saving the question:", error);
          throw error; // Re-throw the error to be caught by the calling function
        }
      },         
      

};

export default QuestionDataService;
