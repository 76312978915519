import React from 'react'
import NewSBALoanCalc from './newSBALoanCalc';
import BusinessDataService from "../../services/BusinessDataService";
import '../../styles/SubmitLOI.css';
import NewDefaultLoan from './newDefaultLoan';
import TaskDataService from '../../services/TaskDataService';

const SubmitLOI = ({business, userId}) => {

  const loiSubmitted = async (id) => {
    try{
    await BusinessDataService.updateLIOStatus(id, true);
    window.location.reload();
    } catch (error){
      console.error(error);
    }
  }; 

  const notInterested = async (id) => { 
    try {
      await BusinessDataService.updateInterestedStatus(id, false);
      // Call completeTasksForBusiness after updating the interested status
      await TaskDataService.completeTasksForBusiness(business.id, userId); 
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };  


  return (
    <div className="submit-loi-container">
      <div className="submit-loi-loi-biz-header">
        <div className="submit-loi-main-information">
          <h1 className="submit-loi-business-name Headers">{business.name}</h1>
          <span className="submit-loi-selling-price Content">Selling Price: {business.selling_price?.toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</span>
          <span className="submit-loi-gross-revenue Content">Gross Revenue: {business.gross_revenue?.toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</span>
          <span className="submit-loi-sde Content">SDE: {business.sde?.toLocaleString('en-US', {style: 'currency',currency: 'USD',})}</span>
        </div>
        <div className="submit-loi-buttons">
          <button type="button" className="submit-loi-submitted-loi button Content"  onClick={() => loiSubmitted(business.id)}>
            Submitted LOI
          </button>
          <button type="button" className="submit-loi-not-interested button Content" onClick={() => notInterested(business.id)}>
            Not Interested
          </button>
        </div>
      </div>
      <div className="submit-loi-main">
        <div className="submit-loi-default-loan">
          <div className="submit-loi-loan-component">
            <NewDefaultLoan business={business} userId={userId}/>
          </div>
        </div>
        <div className="submit-loi-loan-calc">
          <div className="submit-loi-calc-component" >
            <NewSBALoanCalc business={business} userId={userId} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmitLOI
