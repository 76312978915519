import React, { useState, useEffect }  from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import '../../styles/questionprofile.css';
import QuestionDataService from '../../services/QuestionDataService';


const QuestionProfile = ({user}) => {
    const { id } = useParams();
    const [editableQuestion, setEditableQuestion] = useState(null);
    const [question, setQuestion] = useState(null);
    const userId = user.sub;
    const navigate = useNavigate();


    useEffect(() => {
      const fetchQuestionData = async () => {
        try {
          const response = await QuestionDataService.getQuestionById(id, userId);
          const fetchedQuestion = response.data;
          setQuestion(fetchedQuestion);
        } catch (error) {
          console.error("Error fetching Question data:", error);
        }
      };
      fetchQuestionData();
    }, [id, userId]);

    if (!question) {
      return <div>Loading...</div>;
    }

    const businessName = question.business.name;
    const BusinessProfileLink = `/businessProfile/${question.business.id}`;


    const handleEdit = () => {
      setEditableQuestion(true);
    };

    const handleCancel = () => {
      setEditableQuestion(null);
    };

    const handleDelete = async (id) => {
      try {
        await QuestionDataService.deleteQuestion(id, userId);
        navigate(`/businessProfile/${question.business.id}`);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          alert(error.response.data); // Show the error message in an alert
        }
      }
    };

    // Handle changes to the question text
    const handleQuestionChange = (e) => {
      setQuestion({ ...question, question_text: e.target.value });
    };

    // Handle changes to the answer text
    const handleAnswerChange = (e) => {
      setQuestion({ ...question, answer_text: e.target.value });
    };

    const handleSave = async () => {
      if (!question) return;
    
      try {
        // Assuming saveQuestion method accepts question object and userId
        await QuestionDataService.saveQuestion(question, userId);
        setEditableQuestion(null);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          alert(error.response.data); // Show the error message in an alert
        }
      }
    };    

  return (
    <div>
        {editableQuestion ? (
        <div className="question-profile-container">
        <div className="question-profile-header">
            <h1 className="question-profile-title Headers">{businessName}</h1>
            <div className="question-profile-buttons">
            <svg viewBox="0 0 1024 1024" className="question-profile-edit-save-button" onClick={handleSave}>
                  <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="question-profile-edit-cancel-button" onClick={handleCancel}>
                  <path d="M512 854q140 0 241-101t101-241q0-114-74-210l-478 478q96 74 210 74zM170 512q0 114 74 210l478-478q-96-74-210-74-140 0-241 101t-101 241zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                </svg>
            </div>
        </div>
        <div className="question-profile-main">
            <div className="question-profile-question-container">
            <textarea
                placeholder="Write Question Here:"
                className="question-profile-edit-question Content"
                value={question.question_text}
                onChange={handleQuestionChange}
            ></textarea>
            </div>
            <div className="question-profile-answer-container">
            <textarea
                placeholder="Write Answer Here"
                className="question-profile-edit-answer Content"
                value={question.answer_text}
                onChange={handleAnswerChange}
            ></textarea>
            </div>
        </div>
        </div>
        ) : (   
            <div className="question-profile-container">
            <div className="question-profile-header">
              <h1 className="question-profile-title Headers">{<a href={BusinessProfileLink}>{businessName}</a>}</h1>
              <div className="question-profile-buttons">
                <svg viewBox="0 0 1024 1024" className="question-profile-edit" onClick={() => handleEdit()}>
                  <path d="M0 854h1024v170h-1024v-170zM884 172l-84 84-160-160 84-84q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM758 298l-428 428h-160v-160l428-428z"></path>
                </svg>
                <svg viewBox="0 0 1024 1024" className="question-profile-delete" onClick={() => handleDelete(question.id)}>
                  <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
                  <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
                </svg>
              </div>
            </div>
            <div className="question-profile-main">
              <div className="question-profile-question-container">
                <span className="question-profile-question-value Content">{question.question_text}</span>
              </div>
              <div className="question-profile-answer-container">
                <span className="question-profile-text Content">{question.answer_text? question.answer_text: "Add an Answer for this question"}</span>
              </div>
            </div>
          </div>
         )}
    </div>
  )
}

export default QuestionProfile
