import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <a href="/" onClick={() => loginWithRedirect()} style={{ color: "#fff", textDecoration: "none", marginLeft: "1rem" }}>
      Log In
    </a>
  );
};

export default LoginButton;
