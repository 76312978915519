import React from 'react';
import Select from 'react-select';

const MultiSelectPicklist = ({ 
    options, 
    onSelectionChange, 
    placeholder = "Select...", 
    isMulti = true,
    defaultValue  // New prop
}) => {

    const handleChange = selected => {
        const selectedValues = isMulti ? selected.map(item => item.value) : selected ? selected.value : null;
        
        if (onSelectionChange) {
            onSelectionChange(selectedValues);
        }
    };

    const selectOptions = options.map(option => ({ label: option, value: option }));

    // Convert defaultValue to the format react-select expects
    const defaultValues = isMulti ? 
        defaultValue?.map(value => ({ label: value, value })) : 
        defaultValue ? { label: defaultValue, value: defaultValue } : null;

    return (
        <Select
            isMulti={isMulti}
            options={selectOptions}
            placeholder={placeholder}
            onChange={handleChange}
            value={defaultValues}  // Set the default value here
        />
    );
};

export default MultiSelectPicklist;
