import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/TaxInfoStyles.css';
import TaxYearDataService from '../../services/TaxYearDataService';
import {useNavigate  } from 'react-router-dom';


const TaxInfo = ({ business, userId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [taxYears, setTaxYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [averages, setAverages] = useState(null);
  const businessID = business.id;
  const navigate = useNavigate();

// This function will clean the input values
function sanitizeInput(input) {
  if (typeof input === 'string') {
    // Remove any non-numeric characters, excluding the decimal point and minus sign
    const cleanedInput = input.replace(/[^0-9.-]+/g, '');
    // Convert the cleaned string to a number
    const numberValue = parseFloat(cleanedInput);
    // Round to two decimal places
    const roundedValue = Math.round(numberValue * 100) / 100;
    // Check for NaN and handle accordingly
    return isNaN(roundedValue) ? 0 : roundedValue;
  }
  return input;
}

  const calculateAverages = useCallback(() => {
    // Initialize variables to store the sum of each column
    let totalSales = 0;
    let totalCOGS = 0;
    let totalGrossProfit = 0;
    let totalExpenses = 0;
    let totalNetProfit = 0;
    let validYearsCount = 0; // To track the number of valid years for which averages are calculated
  
    // Iterate through the taxYears array
    taxYears.forEach((column) => {
    const sales = sanitizeInput(column.sales);
    const cogs = sanitizeInput(column.cogs);
    const grossProfit = sanitizeInput(column.gross_profit);
    const expenses = sanitizeInput(column.expenses);
    const netProfit = sanitizeInput(column.net_profit);
  
      // Check if the values are numeric and not NaN
      if (!Number.isNaN(sales) && !Number.isNaN(cogs) && !Number.isNaN(grossProfit) && !Number.isNaN(expenses) && !Number.isNaN(netProfit)) {
        // Add the values to the corresponding totals
        totalSales += sales;
        totalCOGS += cogs;
        totalGrossProfit += grossProfit;
        totalExpenses += expenses;
        totalNetProfit += netProfit;
        validYearsCount++;
      }
    });

  
    // Calculate the averages by dividing the totals by the validYearsCount
    const averageSales = validYearsCount > 0 ? Math.round((totalSales / validYearsCount) * 100) / 100 : 0;
    const averageCOGS = validYearsCount > 0 ? Math.round((totalCOGS / validYearsCount) * 100) / 100 : 0;
    const averageGrossProfit = validYearsCount > 0 ? Math.round((totalGrossProfit / validYearsCount) * 100) / 100 : 0;
    const averageExpenses = validYearsCount > 0 ? Math.round((totalExpenses / validYearsCount) * 100) / 100 : 0;
    const averageNetProfit = validYearsCount > 0 ? Math.round((totalNetProfit / validYearsCount) * 100) / 100 : 0;
  
    // Return the calculated averages
    return {
      averageSales,
      averageCOGS,
      averageGrossProfit,
      averageExpenses,
      averageNetProfit,
    };
  }, [taxYears]);


  useEffect(() => {
    if (businessID) {
      setIsLoading(true);
  
      TaxYearDataService.getTaxYearById(businessID, userId)
        .then((data) => {
          data.sort((a, b) => a.year - b.year);
          setTaxYears(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [businessID]);

  useEffect(() => {
    if (taxYears && taxYears.length > 0) {
      const averages = calculateAverages();
      setAverages(averages);
      try {
        TaxYearDataService.saveAverages(averages, businessID, userId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [taxYears]);

  

  const handleSave = async (business) => {
    setIsEditing(false);
  
    for (const column of taxYears) {
        // Perform PUT request for existing tax year
        try {
          const response = await TaxYearDataService.updateTaxYear(column);
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    if (taxYears.length > 0) {
      const averages = calculateAverages();
      setAverages(averages);
      try {
        TaxYearDataService.saveAverages(averages, businessID, userId);
      } catch (error) {
        console.error(error);
      }
    }
  };    

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const sanitizedValue = sanitizeInput(value);
    const updatedColumns = [...taxYears];
    updatedColumns[index][name] = sanitizedValue;
    setTaxYears(updatedColumns);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  
  const handleDelete = async () => {
    const columnsToDelete = taxYears.filter(column => column.isMarkedForDeletion);
    
    const deletePromises = columnsToDelete.map(column => {
      return TaxYearDataService.deleteTaxYear(column.id, userId);
    });
  
    try {
      await Promise.all(deletePromises);  // Wait for all delete requests to complete
  
      const updatedColumns = taxYears.filter((column) => !column.isMarkedForDeletion);
      setTaxYears(updatedColumns);
  
    } catch (error) {
      console.error("Error deleting tax years:", error);
    }
  };

  const handleCheckboxChange = (columnId, event) => {
    const updatedColumns = taxYears.map((column) => {
      if (column.id === columnId) {
        return {
          ...column,
          isMarkedForDeletion: !column.isMarkedForDeletion,
        };
      }
      return column;
    });
    setTaxYears(updatedColumns);
};


  return (
    <div>
      <h1 className='Headers'>Taxes</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {taxYears && taxYears.length === 0 ? (
            <>
              <button className='Content' onClick={() => {navigate(`/add-taxYear/${businessID}`);}}>New Year</button>
              <p>No tax years available</p>
            </>
          ) : (
            <div>
              {isEditing !== false ? (
                <>
                  <button className='Content' onClick={() => handleSave(business)}>Save</button>
                  <button className='Content' onClick={handleCancel}>Cancel</button>
                </>
              ) : (
                <>
                  <button className='buttons Content' onClick={handleEdit}>Edit</button>
                  <button className='buttons Content' onClick={() => {navigate(`/add-taxYear/${businessID}`);}}>New Year</button>
                  {!isEditing && (
                    <button className='buttons Content' onClick={handleDelete}>Delete</button>
                  )}
                </>
              )}
              <div className="tax-info-container Content">
                <table>
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Year</th>
                      <th>Gross Receipts/Sales:</th>
                      <th>Cost of Goods</th>
                      <th>Gross Profit:</th>
                      <th>Total Expenses</th>
                      <th>Net Profit/Loss:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxYears && taxYears.map((column, index) => (
                      <tr key={column.id}>
                        <td>
                          {isEditing ? (
                            <></>
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => handleCheckboxChange(column.id, e)}
                              checked={column.isMarkedForDeletion || false}
                            />
                          )}
                        </td>
                        <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="year"
                            value={column.year}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '50px' }}
                          />
                        ) : (
                          column.year
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="sales"
                            value={column.sales}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '70px' }}
                          />
                        ) : (
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(column.sales)
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="cogs"
                            value={column.cogs}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '70px' }}
                          />
                        ) : (
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(column.cogs)
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="gross_profit"
                            value={column.gross_profit}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '70px' }}
                          />
                        ) : (
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(column.gross_profit)
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="expenses"
                            value={column.expenses}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '70px' }}
                          />
                        ) : (
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(column.expenses)
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <input
                            type="text"
                            name="net_profit"
                            value={column.net_profit}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: '70px' }}
                          />
                        ) : (
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(column.net_profit)
                        )}
                      </td>
                      </tr>
                    ))}
                    
                    {/* The "Years Averaged" row is integrated here */}
                    {averages !== null && ( // Check if averages is not null
                      <tr className="averaged-row">
                        <td colSpan="2">Years Averaged</td>
                        <td>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(averages.averageSales)}
                        </td>
                        <td>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(averages.averageCOGS)}
                        </td>
                        <td>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(averages.averageGrossProfit)}
                        </td>
                        <td>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(averages.averageExpenses)}
                        </td>
                        <td>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(averages.averageNetProfit)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );   
};

export default TaxInfo;