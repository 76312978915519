import React, { useState }  from 'react'
import '../../styles/createswot.css';
import { useNavigate, useParams } from "react-router-dom";
import AnalysisDataService from '../../services/AnalysisDataService';

const CreateSwot = (props) => {

    const { businessId } = useParams();
    const navigate = useNavigate();

    const [status, setStatus] = useState('');
    const [strengths, setStrengths] = useState('');
    const [weaknesses, setWeaknesses] = useState('');
    const [opportunities, setOpportunities] = useState('');
    const [threats, setThreats] = useState('');

    const saveSWOT = () => {
        if (!businessId || status === '' || status === 'Choose') {
            alert('Please select a valid status and ensure business ID is present.');
            return;
        }

        AnalysisDataService.createSWOT(businessId, status, strengths, weaknesses, opportunities, threats)
            .then(response => {
                navigate(`/swot-profile/${response.data.analysis_id}`);
            })
            .catch(error => {
                console.error('Error saving SWOT analysis:', error);
                alert('Error saving SWOT analysis. Please try again.');
            });
    };

    return (
        <div className="swot-analysis-container">
          <div className="swot-analysis-header">
            <h1 className="swot-analysis-swot-analysis Headers">SWOT Analysis</h1>
          </div>
          <div className="swot-analysis-container1">
            <span className="swot-analysis-text Content">
              <span>
                Chat GPT Prompt Example: Perform a comprehensive business analysis
                for [insert business description] including a SWOT analysis to
                identify strengths, weaknesses, opportunities, and threats.
              </span>
              <br></br>
            </span>
          </div>
          <div className="swot-analysis-status-container">
            <select onChange={e => setStatus(e.target.value)}>
              <option value="Choose">Choose Status</option>
              <option value="started">Started</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          <div className="swot-analysis-main">
            <div className="swot-analysis-strenths-and-opp">
              <div className="swot-analysis-strength-row">
                <div className="swot-analysis-strength-column">
                  <h1 className="swot-analysis-strenght-title Content">
                    Strengths
                  </h1>
                  <textarea
                    placeholder="Strengths"
                    className="swot-analysis-strength-input textarea"
                    onChange={e => setStrengths(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="swot-analysis-opp-row">
                <div className="swot-analysis-opp-column">
                  <h1 className="swot-analysis-opp-title Content">Opportunities</h1>
                  <textarea
                    placeholder="Opportunities"
                    className="swot-analysis-opp-input textarea"
                    onChange={e => setOpportunities(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="swot-analysis-weaknesses-and-threats">
              <div className="swot-analysis-weak-row">
                <div className="swot-analysis-weak-column">
                  <h1 className="swot-analysis-weak-title Content">Weaknesses</h1>
                  <textarea
                    placeholder="Weaknesses"
                    className="swot-analysis-weak-input textarea"
                    onChange={e => setWeaknesses(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="swot-analysis-theats-row">
                <div className="swot-analysis-threats-column">
                  <h1 className="swot-analysis-threats-title Content">Threats</h1>
                  <textarea
                    placeholder="Threats"
                    className="swot-analysis-threats-input textarea"
                    onChange={e => setThreats(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="swot-analysis-button-container">
            <button
              name="Add Question"
              type="button"
              className="swot-analysis-save-swot button Content"
              onClick={saveSWOT}
            >
              Save SWOT
            </button>
          </div>
        </div>
      )
}

export default CreateSwot
