import React, { useState, useEffect } from 'react';
import '../styles/ProgressBar.css';

const ProgressBar = ({ activeStep, onStepClick, business }) => {
  const [steps, setSteps] = useState([
    { title: 'Submit NDA', isOnTime: true, isCompleted: false, notInterested: null },
    { title: 'Indication of Interest', isOnTime: true, isCompleted: false, notInterested: null },
    { title: 'Submit LOI', isOnTime: true, isCompleted: false, notInterested: null },
    { title: 'Due Diligence', isOnTime: true, isCompleted: false, notInterested: null },
    // Add more steps as needed
  ]);

  useEffect(() => {
    if (!business.interested_in_business) {
      setSteps((prevSteps) =>
        prevSteps.map((step, index) => {
          const updatedStep = {
            ...step,
            isOnTime: false,
            isCompleted: true,
            notInterested: true,
          };
          return updatedStep;
        })
      );
    }
  
    if (business.nda_submitted) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[0] = {
            ...updatedSteps[0],
            isCompleted: true,
          };
        }
        return updatedSteps;
      });
    }
    const currentDate = new Date();
    const createdDate = new Date(business.date_created);
    
    const twoDaysAgo = new Date(currentDate);
    twoDaysAgo.setDate(currentDate.getDate() - 2);
    
    const twoWeeksAgo = new Date(currentDate);
    twoWeeksAgo.setDate(currentDate.getDate() - 14);
    
    const fourWeeksAgo = new Date(currentDate);
    fourWeeksAgo.setDate(currentDate.getDate() - 28);
    

    if (createdDate < twoDaysAgo && !business.nda_submitted && business.interested_in_business) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[0] = {
            ...updatedSteps[0],
            isOnTime: false,
          };
        }
        return updatedSteps;
      });
    }

    if (createdDate < twoWeeksAgo && !business.preloi_submitted && business.interested_in_business) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[1] = {
            ...updatedSteps[1],
            isOnTime: false,
          };
        }
        return updatedSteps;
      });
    }
    if (createdDate < fourWeeksAgo && !business.loi_submitted && business.interested_in_business) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[2] = {
            ...updatedSteps[2],
            isOnTime: false,
          };
        }
        return updatedSteps;
      });
    }

    if (business.preloi_submitted) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[1] = {
            ...updatedSteps[1],
            isCompleted: true,
          };
        }
        return updatedSteps;
      });
    }

    if (business.loi_submitted) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[2] = {
            ...updatedSteps[2],
            isCompleted: true,
          };
        }
        return updatedSteps;
      });
    }

    if (business.dua_diligence_completed) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        if (updatedSteps.length > 0) {
          updatedSteps[3] = {
            ...updatedSteps[3],
            isCompleted: true,
          };
        }
        return updatedSteps;
      });
    }

  }, [business.interested_in_business, business.nda_submitted, business.date_created, business.preloi_submitted, business.dua_diligence_completed, business.loi_submitted]);


  

  return (
    <div>
      <div className="progress-bar Message">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index === activeStep ? 'active' : ''} ${
              step.isOnTime ? 'on-time' : 'late'
            } ${step.isCompleted ? 'completed' : ''}`}
            onClick={() => onStepClick(index)}
          >
            <span className="step-title Message">{step.title}</span>
          </div>
        ))}
      </div>
      {!steps[activeStep].isOnTime && !steps[activeStep].notInterested && (
        <h1 className="late-message Headers">
          This Step is late. Complete the Step or mark the business as uninterested.
        </h1>
      )}
    </div>
  );
};

export default ProgressBar;
