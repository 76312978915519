import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const KanbanBoard = ({ user }) => {
  const [boardData, setBoardData] = useState({ tasks: {}, columns: {}, columnOrder: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.error("User data is missing");
      setError("User data is missing");
      setLoading(false);
      return;
    }

    const userId = user.sub;

    const fetchData = async () => {
      try {
        const steps = ['NDA not Submitted', 'NDA Submitted', 'Pre LOI Complete', 'LOI Submitted', 'Complete', 'Not Interested'];
        const businesses = await getBusinesses(userId);
        const { tasks, columns, columnOrder } = initializeBoardData(steps, businesses);
        setBoardData({ tasks, columns, columnOrder });
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const initializeBoardData = (steps, businesses) => {
    const tasks = {};
    const columns = {};
    const columnOrder = steps;

    businesses.forEach((business) => {
      tasks[business.id] = { id: business.id, content: business.name };
    });

    steps.forEach((step) => {
      columns[step] = {
        id: step,
        title: step,
        taskIds: businesses.filter(b => b.current_step === step).map(b => b.id),
      };
    });

    return { tasks, columns, columnOrder };
  };

  const getBusinesses = async (userId) => {
    try {
      const res = await fetch(`/businesses/${userId}`);
      if (!res.ok) {
        throw new Error(`Failed to fetch businesses: ${res.statusText}`);
      }
      const busArray = await res.json();
      return busArray;
    } catch (error) {
      console.error("Failed to fetch businesses:", error);
      throw error;
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = boardData.columns[source.droppableId];
    const finish = boardData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setBoardData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...boardData,
      columns: {
        ...boardData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setBoardData(newState);
  };

  const handleCardClick = (taskId) => {
    navigate(`/businessProfile/${taskId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {boardData.columnOrder.map((columnId) => {
        const column = boardData.columns[columnId];
        const tasks = column.taskIds.map((taskId) => boardData.tasks[taskId]);

        return (
          <Droppable droppableId={column.id} key={column.id}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ margin: '8px', border: '1px solid lightgrey', borderRadius: '2px', width: '220px', display: 'inline-block' }}
              >
                <h3 style={{ padding: '8px' }}>{column.title}</h3>
                {tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          padding: '16px',
                          margin: '0 0 8px 0',
                          minHeight: '50px',
                          backgroundColor: '#fff',
                          color: 'black',
                          ...provided.draggableProps.style,
                        }}
                        onClick={() => handleCardClick(task.id)}
                      >
                        {task.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      })}
    </DragDropContext>
  );
};

export default KanbanBoard;
