import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import OfferDataService from "../../services/OfferDataService";

const AddOffer = ({ user }) => {
  const [purchaseprice, setPurchasePrice] = useState("");
  const [bank_fee, setBankFee] = useState("");
  const [due_diligence, setDueDiligence] = useState("");
  const [downpaymentPercentage, setDownpaymentPercentage] = useState("");
  const [sellerFinancingPercentage, setSellerFinancingPercentage] = useState("");
  const [workingCapital, setworkingCapital] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const [business, setBusiness] = useState("");
  const navigate = useNavigate();
  
  console.log("User:", user.sub);

  useEffect(() => {
    const businessId = location.pathname.split("/").pop();
    console.log("Business id: " + businessId);
    if (businessId) {
      setBusiness(businessId); // Update the broker text box with the broker id
    }
  }, [location]);


  function handleSubmit(event){
    event.preventDefault();
    // Get the user ID from the user object
    const userId = user.sub;

    OfferDataService.createOffer(userId, purchaseprice, bank_fee, due_diligence, workingCapital, description, business, downpaymentPercentage, sellerFinancingPercentage)
    .then(response => {
      const offerId = response.data.id;
      navigate(`/offer/${offerId}`);
    })
    .catch(error => {
      console.error(error);
      alert('Failed to add offer');
    })

  }





  return (
    <div>
      <h1>Add Offer</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Purchase Price:
          <input
            type="text"
            value={purchaseprice}
            onChange={(e) => setPurchasePrice(e.target.value)}
          />
        </label>
        <br />
        <label>
          Bank Fee:
          <input
            type="text"
            value={bank_fee}
            onChange={(e) => setBankFee(e.target.value)}
          />
        </label>
        <br />
        <label>
          Due Diligence:
          <input
            type="text"
            value={due_diligence}
            onChange={(e) => setDueDiligence(e.target.value)}
          />
        </label>
        <br />
        <label>
          Working Capital:
          <input
            type="text"
            value={workingCapital}
            onChange={(e) => setworkingCapital(e.target.value)}
          />
        </label>
        <br />
        <label>
          Downpayment %:
          <input
            type="text"
            value={downpaymentPercentage}
            onChange={(e) => setDownpaymentPercentage(e.target.value)}
          />
        </label>
        <br />
        <label>
          Seller Financing %:
          <input
            type="text"
            value={sellerFinancingPercentage}
            onChange={(e) => setSellerFinancingPercentage(e.target.value)}
          />
        </label>
        <br />
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Add Offer</button>
      </form>
    </div>
  );
};

export default AddOffer;
