import React from 'react'
import '../../styles/PreLOI.css';
import "../../styles/Styles.css";
import TaxInfo from "./TaxInfo";
import TaskCheckList from "./TaskCheckList";
import BusinessDataService from '../../services/BusinessDataService';
import TaskDataService from '../../services/TaskDataService';

const PreLOI = ({business, userId}) => {

  const notInterested = async (id) => { 
    try {
      await BusinessDataService.updateInterestedStatus(id, false);
      // Call completeTasksForBusiness after updating the interested status
      await TaskDataService.completeTasksForBusiness(business.id, userId); 
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }; 
  
  const PreLOISubmitted = async (id) => {
    if(business.nda_submitted){
      try{
      await BusinessDataService.updatePreLOIStatus(id, true);
      window.location.reload();
      } catch (error){
        console.error(error);
      }
    }else{
      alert("Step Submit NDA has not been completed");
    }
  };

  return (
    <div className="pre-loi-container">
      <div className="pre-loi-nda-header">
        <div className="pre-loi-main-information">
          <h1 className="pre-loi-business-name Headers">{business.name}</h1> 
          <span className="pre-loi-selling-price Content">Selling Price: {business.selling_price ? `$${business.selling_price.toLocaleString()}` : 'N/A'}</span>
          <span className="pre-loi-gross-revenue Content">Gross Revenue: {business.gross_revenue ? `$${business.gross_revenue.toLocaleString()}` : 'N/A'}</span>
          <span className="pre-loi-sde Content">SDE: {business.sde ? `$${business.sde.toLocaleString()}` : 'N/A'}</span>
        </div>
        <div className="pre-loi-buttons">
          <button type="button" className="pre-loi-button button Content" onClick={() => PreLOISubmitted(business.id)}>
            Completed IOI
          </button>
          <button type="button" className="pre-loi-button1 button Content" onClick={() => notInterested(business.id)}>
            Not Interested
          </button>
        </div>
      </div>
      <div className="pre-loi-main">
        <div className="pre-loi-taxes-container">
          <TaskCheckList business={business} userId={userId} />
        </div>
        <div className="pre-loi-task-container">
          <TaxInfo business={business} userId={userId} />
        </div>
      </div>
    </div>
  )
}

export default PreLOI
