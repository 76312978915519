import React from 'react';
import LoginButton from './LoginButton';
import Hometabs from '../Hometabs';


function Home() {

  return (
    <div>
        <Hometabs />
        <div>
            <h1>Welcome to AcquireHub</h1>
        </div>
    </div>
  );
}

export default Home;
