import React, { useState, useEffect } from "react";
import BrokerDataService from "../../services/BrokerDataService";
import "../../styles/tableStyles.css"; 
import {useNavigate  } from 'react-router-dom';
import "../../styles/SelectBrokerStyles.css";

const SelectBroker = ({ user }) => {

  const [brokers, setBrokers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate(); // Add useNavigate hook
  const userId = user.sub

  async function getBrokers(userId) {
    const res = await fetch(`/brokers/${userId}`);
    const brokerArray = await res.json();
    setBrokers(brokerArray);
  }
  

  useEffect(() => {
    getBrokers(userId);
  }, [userId]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Remove unnecessary toString() conversion
  };
  
  const handleSearchClick = () => {
    if(searchTerm){
    BrokerDataService.searchBrokers(searchTerm, userId)
      .then((response) => {
        setBrokers(response.data);
      })
      .catch((error) => {
        console.error("Error searching brokers:", error);
      });
    } else{
      getBrokers(userId);
    }
  };


  return (
    <div className="select-broker-container">
      <div className="select-broker-broker-header">
        <h1 className="select-broker-header Headers">
          Select the Broker that is listing this Business/Add a New Broker
        </h1>
        <div className="select-broker-container1">
          <svg
            onClick={() => {navigate("/add-broker");}}
            viewBox="0 0 804.5714285714286 1024"
            className="select-broker-add-button"
          >
            <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
          </svg>
          <input
            type="text"
            placeholder="Search for Broker ..."
            className="select-broker-search-input input Content"
            onChange={handleSearch}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
              handleSearchClick();
              }
            }}
          />
          <svg
            viewBox="0 0 950.8571428571428 1024"
            className="select-broker-search-button"
            onClick={handleSearchClick}
          >
            <path d="M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z"></path>
          </svg>
        </div>
      </div>
      <div className="select-broker-broker-table">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Broker Name</th>
              <th>Phone Number</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(brokers) &&
              brokers.map((broker, index) => (
                <tr key={broker.id}>
                  <td>{index + 1}</td>
                  <td>
                    <a href={`/add-business/${broker.id}`}>{broker.broker_name}</a>
                  </td>
                  <td>{broker.phone_number}</td>
                  <td>{broker.email}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}

export default SelectBroker
