import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditOffer = ({ offers, updateOffer }) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [offer, setOffer] = useState({
    PurchasingPrice: "",
    SellersNote: "",
  });

  useEffect(() => {
    const selectedOffer = offers.find((offer) => offer.id === parseInt(id));
    setOffer(selectedOffer);
  }, [offers, id]);

  const { PurchasingPrice, SellersNote } = offer;

  const onInputChange = (e) => {
    setOffer({ ...offer, [e.target.PurchasingPrice]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateOffer(offer);
    navigate("/offers-list"); // Navigate to Broker list page
  };

  return (
    <div>
      <h1>Edit Offer</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="PurchasingPrice">Purchasing Price</label>
        <input
          type="text"
          name="PurchasingPrice"
          value={PurchasingPrice}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <label htmlFor="SellersNote">SellersNote</label>
        <input
          type="text"
          name="SellersNote"
          value={SellersNote}
          onChange={(e) => onInputChange(e)}
        />
        <br />
        <button type="submit">Update Offer</button>
      </form>
    </div>
  );
};

export default EditOffer;