import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BusinessDataService from "../../services/BusinessDataService";
import "../../styles/AddBusiness.css";
import "../../styles/Styles.css";
import UserLimitDataService from "../../services/UserLimitDataService";

const AddBusiness = ({ user }) => {

  const [Name, setName] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [broker, setBroker] = useState("");
  const [franchise, setFranchise] = useState(false); // Added state for franchise
  const [listingUrl, setListingUrl] = useState(""); // Added state for listing url
  const [yearEstablished, setYearEstablished] = useState(""); // Added state for year established
  const location = useLocation();
  const [numberOfEmployees, setNumberOfEmployees] = useState(""); // Added state for number of employees
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [sde, setsde] = useState('');
  const [gross_revenue, setgross_revenue] = useState('');
  const [business_type, setBusinessType] = useState("None"); // Initialize with "None" or your default value
  const [validationErrors, setValidationErrors] = useState([]);
  const navigate = useNavigate();

  const US_STATES = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
    'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  useEffect(() => {
    const brokerId = location.pathname.split("/").pop();
    console.log("Broker id: " + brokerId);
    if (brokerId) {
      setBroker(brokerId); // Update the broker text box with the broker id
    }
  }, [location]);

  const validate = () => {
    let errors = [];
    
    if (!Name || Name.trim() === "") {
      errors.push("Name is required. ");
    }
    if (!sde || sde.trim() === "") {
      errors.push("SDE is required. ");
    }
    if (!SellingPrice || SellingPrice.trim() === "") {
      errors.push("Selling Price is required. ");
    }
    if (!gross_revenue || gross_revenue.trim() === "") {
      errors.push("Gross Revenue is required. ");
    }
    
    setValidationErrors(errors);
    return errors.length === 0;  // Return true if there are no errors, false otherwise
  };  

  const handleBusinessTypeChange = (e) => {
    setBusinessType(e.target.value);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    const userId = user.sub;
  
    try {
      // First, check if the user has reached their business limit
      const hasBusinessLimit = await UserLimitDataService.checkBusinessLimit(userId);
      if (!hasBusinessLimit) {
        alert('You have reached your limit for adding businesses.');
        return;
      }
  
      // Parse the financial fields
      const parsedSellingPrice = SellingPrice.replace(/[^0-9]/g, '');
      const sellingPriceAsInt = parseInt(parsedSellingPrice, 10);
      const parsedSDE = sde.replace(/[^0-9]/g, '');
      const sdeInt = parseInt(parsedSDE, 10);
      const parsedGross = gross_revenue.replace(/[^0-9]/g, '');
      const GrossRevInt = parseInt(parsedGross, 10);
  
      // Create the business
      const response = await BusinessDataService.createBusiness(
        Name,
        sellingPriceAsInt,
        broker,
        franchise,
        listingUrl,
        yearEstablished,
        numberOfEmployees,
        userId,
        city,
        state,
        sdeInt,
        GrossRevInt,
        business_type,
        userId
      );
      const businessId = response.data.id;
      console.log("Business created:", businessId);
  
      // Increment the business count
      await UserLimitDataService.incrementBusinessCount(userId);
  
      // Navigate to the business profile page
      navigate(`/businessProfile/${businessId}`);
    } catch (error) {
      alert('Failed to add business: ' + error.message);
      console.error("Failed to create business:", error.message);
    }
  };


  return (
    <div className="add-business-container">
      <div className="add-business-header">
        <h1 className="add-business-title Headers">Create Business</h1>
      </div>
        {validationErrors.map((error, index) => (
          <div key={index} className="error-message">{error}</div>
        ))}
      <form className="add-business-form">
        <div className="add-business-left-fields">
          <input
            type="text"
            placeholder="Business Name:"
            required
            className="add-business-business-name Content input"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="SDE: "
            required
            className="add-business-sde Content input"
            value={sde}
            onChange={(e) => setsde(e.target.value)}
          />
          <input
            type="url"
            placeholder="Listing URL:"
            className="add-business-listing-url Content input"
            value={listingUrl}
            onChange={(e) => setListingUrl(e.target.value)}
          />
          <input
            type="number"
            placeholder="Number of Employees:"
            className="add-business-number-of-employees Content input"
            value={numberOfEmployees}
            onChange={(e) => setNumberOfEmployees(e.target.value)}
          />
          <select className="add-business-business-type" value={business_type} onChange={handleBusinessTypeChange}>
            <option value="Select Business Type">Select Business Type</option>
            <option value="Distribution">Distribution</option>
            <option value="Product">Product</option>
            <option value="Service">Service</option>
          </select>
          <label className="add-business-franchise-label Content">
            Franchise:
          </label>
        </div>
        <div className="add-business-right-fields">
          <input
            type="text"
            placeholder="Selling Price:"
            required
            className="add-business-selling-price Content input"
            value={SellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
          />
          <input
            type="text"
            placeholder="Gross Revenue:"
            className="add-business-gross-revenue Content input"
            value={gross_revenue}
            onChange={(e) => setgross_revenue(e.target.value)}
          />
          <input
            type="number"
            placeholder="Year Established:"
            className="add-business-year-established Content input"
            value={yearEstablished}
            onChange={(e) => setYearEstablished(e.target.value)}
          />
          <input
            type="text"
            placeholder="City:"
            className="add-business-city Content input"
            value={city} 
            onChange={(e) => setCity(e.target.value)}
          />
          <select className="add-business-state" value={state} onChange={(e) => setState(e.target.value)}>
          <option value="">-- Select a state --</option>
                {US_STATES.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
          </select>
          <input
            type="checkbox"
            checked={franchise}
            className="add-business-franchise-checkbox"
            onChange={(e) => setFranchise(e.target.checked)}
          />
        </div>
      </form>
      <button
        type="button"
        onClick={handleSubmit}
        className="add-business-create-button Content button"
      >
        Create Business
      </button>
    </div>
  )
}

export default AddBusiness
