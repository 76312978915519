import React, { useState }  from 'react'
import '../../styles/addquestion.css';
import QuestionDataService from '../../services/QuestionDataService';
import { useNavigate, useParams } from "react-router-dom";

const AddQuestion = ({ user }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const navigate = useNavigate();
    const userId = user.sub;
    const { businessId } = useParams();
  
    const handleQuestionChange = (event) => {
      setQuestion(event.target.value);
    };
  
    const handleAnswerChange = (event) => {
      setAnswer(event.target.value);
    };


    const handleSubmit = async () => {
        if (!question) {
          alert("Please enter a question.");
          return;
        }
        // If user is not set or any other condition based on your application's logic
        if (!user) {
          alert("You must be logged in to add a question.");
          return;
        }
        try {
            // Construct the payload
            const payload = {
              questionText: question,
              answerText: answer,
              user_id: userId,
              business_id: businessId,
            };
      
            // Call the service function to create the question
            const response = await QuestionDataService.createQuestion(payload);
            // Check if the response includes the id
            if (response) {
                
              // Use the ID from the response to navigate to the question profile page
              navigate(`/question-profile/${response}`);
            } else {
              throw new Error('Question ID was not returned by the server.');
            }
          } catch (error) {
            console.error("Failed to add question:", error);
            alert("Failed to add the question. Please try again later.");
          }
        };

  return (
    <div className="add-question-container">
      <div className="add-question-header">
        <h1 className="add-question-add-question Headers">Add Question</h1>
      </div>
      <div className="add-question-main">
        <div className="add-question-question-container">
          <textarea
            placeholder="Write Question Here:"
            className="add-question-question textarea Content"
            value={question}
            onChange={handleQuestionChange}
          ></textarea>
        </div>
        <div className="add-question-answer-container">
          <textarea
            placeholder="Write Answer Here"
            className="add-question-answer textarea Content"
            value={answer}
            onChange={handleAnswerChange}
          ></textarea>
        </div>
      </div>
      <div className="add-question-button-container">
        <button
          type="button"
          name="Add Question"
          className="add-question-button button Content"
          onClick={handleSubmit}
        >
          Add Question
        </button>
      </div>
    </div>
  )
}

export default AddQuestion
