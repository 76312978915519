import React from 'react';

const RangeFilterComponent = ({
  placeholderStart,
  placeholderEnd,
  onFilterChange,
  startFieldName,
  endFieldName
}) => {
  const handleStartChange = (e) => {
    const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
    onFilterChange((prevState) => ({
      ...prevState,
      [startFieldName]: newValue,
    }));
  };

  const handleEndChange = (e) => {
    const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
    onFilterChange((prevState) => ({
      ...prevState,
      [endFieldName]: newValue,
    }));
  };

  return (
    <div className="range-filter">
      <input
        type="number"
        placeholder={placeholderStart}
        onChange={handleStartChange}
        style={{
          width: '80px',
          marginRight: '8px',
          borderRadius: '4px',
          border: '1px solid #295069',
          height: '35px'
        }}
      />
      <input
        type="number"
        placeholder={placeholderEnd}
        onChange={handleEndChange}
        style={{
          width: '80px',
          marginRight: '8px',
          borderRadius: '4px',
          border: '1px solid #295069',
          height: '35px'
        }}
      />
    </div>
  );
};

export default RangeFilterComponent;
